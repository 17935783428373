import http from "./http";

export const getCategoryList = () => {
  return http.post("/rp_api/member/category_list");
};

export const getArticleList = (params) => {
  return http.post("/rp_api/member/article_list", params);
};

export const getArticleDetails = (Id) => {
  return http.get("/rp_api/member/article_get", {
    data: JSON.stringify({ Id }),
  });
};

export const getAdvertiseList = () => {
  return http.post("/rp_api/member/advertise_list");
};

export const getAdvBrandSiteList = () => {
  return http.post("/rp_api/member/adv_brand_site_list");
};

export const getBasicSiteInfo = () => {
  return http.post("/rp_api/member/get_basic_site_info");
};

export const getArticleCommentsList = (Id) => {
  return http.get("/rp_api/member/get_article_comments_list", {
    data: JSON.stringify({ Id }),
  });
};

export const postComments = (params) => {
  return http.get("/member_api/article/post_comments", {
    data: JSON.stringify(params),
  });
};

import Vue from "vue";
import Vuex from "vuex";
import { getAdvertiseList, getCategoryList } from "@/axios/api";
// import i18n from "@/locales";
import { getCookie } from "@/config/utils";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    navbar: [],
    advertiseList: [],
    lang: getCookie("lang"),
  },
  getters: {
    advertiesSort(state) {
      return state.advertiseList.reduce((prev, curr) => {
        if (!prev[curr.AdvertiseType]) {
          prev[curr.AdvertiseType] = [];
        }
        prev[curr.AdvertiseType].push(curr);
        return prev;
      }, {});
    },
  },
  mutations: {
    SET_ADVERTISE_LIST(state, payload) {
      state.advertiseList = payload;
    },
    SET_LANGUAGE(state, payload) {
      state.lang = payload;
    },
    SET_CATEGORY_LIST(state, payload) {
      state.navbar = payload;
    },
  },
  actions: {
    async getAdvertiseList({ commit }) {
      const res = await getAdvertiseList();
      commit("SET_ADVERTISE_LIST", res.data);
    },
    async getCategoryList({ commit }) {
      const res = await getCategoryList();
      commit("SET_CATEGORY_LIST", res.data);
    },
  },
  modules: {},
});

<template>
  <div class="betting-sites">
    <section class="best-online-casinos">
      <div class="container">
        <el-row class="row">
          <el-col class="">
            <el-col class="" :xs="20" :sm="20" :md="20" :push="2" :pull="2">
              <div class="hero hero-default">
                <h1 class="head head-title">
                  {{ $t("app_0083") }} <b>{{ $t("app_0084") }}</b>
                  {{ $t("app_0222") }}
                </h1>
                <p>
                  {{ $t("app_0085") }}
                </p>
              </div>
            </el-col>
          </el-col>
          <el-col id="tabs" class="real-money__tabs">
            <div
              class="tab-content tab-modern"
              id="tab-4"
              style="display: block"
            >
              <div class="row">
                <el-col class="order-1" :md="8">
                  <div class="box-item box-item-modern">
                    <div class="rank">
                      <span>#1</span>
                    </div>
                    <div class="box-top">{{ $t("app_0065") }}</div>
                    <div class="box-item__rating_flag">
                      <div class="box-item__flag">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_3385_71801)">
                            <path
                              d="M12 24.5C18.6274 24.5 24 19.1274 24 12.5C24 5.87258 18.6274 0.5 12 0.5C5.37258 0.5 0 5.87258 0 12.5C0 19.1274 5.37258 24.5 12 24.5Z"
                              fill="#F0F0F0"
                            ></path>
                            <path
                              d="M11.4805 12.5001H24.0022C24.0022 11.417 23.8578 10.3677 23.5888 9.36963H11.4805V12.5001Z"
                              fill="#D80027"
                            ></path>
                            <path
                              d="M11.4805 6.23934H22.2409C21.5063 5.04065 20.5671 3.98114 19.472 3.10889H11.4805V6.23934Z"
                              fill="#D80027"
                            ></path>
                            <path
                              d="M12.0011 24.5003C14.8252 24.5003 17.421 23.5242 19.4709 21.8916H4.53125C6.58109 23.5242 9.17689 24.5003 12.0011 24.5003Z"
                              fill="#D80027"
                            ></path>
                            <path
                              d="M1.76195 18.7608H22.2394C22.8291 17.7985 23.2865 16.7467 23.5873 15.6304H0.414062C0.714859 16.7467 1.17222 17.7985 1.76195 18.7608V18.7608Z"
                              fill="#D80027"
                            ></path>
                            <path
                              d="M5.55862 2.37397H6.65217L5.63498 3.11295L6.02353 4.30869L5.00639 3.5697L3.98925 4.30869L4.32487 3.2757C3.42928 4.02172 2.64431 4.89575 1.99744 5.86963H2.34783L1.70034 6.34002C1.59947 6.5083 1.50272 6.67925 1.41 6.85273L1.71919 7.80434L1.14234 7.38523C0.998953 7.68903 0.867797 7.99967 0.749906 8.31678L1.09055 9.36528H2.34783L1.33064 10.1043L1.71919 11.3L0.702047 10.561L0.0927656 11.0037C0.0317812 11.4939 0 11.9932 0 12.5H12C12 5.87262 12 5.09131 12 0.5C9.62944 0.5 7.41961 1.18766 5.55862 2.37397V2.37397ZM6.02353 11.3L5.00639 10.561L3.98925 11.3L4.3778 10.1043L3.36061 9.36528H4.61789L5.00639 8.16955L5.39489 9.36528H6.65217L5.63498 10.1043L6.02353 11.3ZM5.63498 6.60861L6.02353 7.80434L5.00639 7.06536L3.98925 7.80434L4.3778 6.60861L3.36061 5.86963H4.61789L5.00639 4.67389L5.39489 5.86963H6.65217L5.63498 6.60861ZM10.3279 11.3L9.31073 10.561L8.29359 11.3L8.68214 10.1043L7.66495 9.36528H8.92223L9.31073 8.16955L9.69923 9.36528H10.9565L9.93933 10.1043L10.3279 11.3ZM9.93933 6.60861L10.3279 7.80434L9.31073 7.06536L8.29359 7.80434L8.68214 6.60861L7.66495 5.86963H8.92223L9.31073 4.67389L9.69923 5.86963H10.9565L9.93933 6.60861ZM9.93933 3.11295L10.3279 4.30869L9.31073 3.5697L8.29359 4.30869L8.68214 3.11295L7.66495 2.37397H8.92223L9.31073 1.17823L9.69923 2.37397H10.9565L9.93933 3.11295Z"
                              fill="#0052B4"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_3385_71801">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0 0.5)"
                              ></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="box-item__rating">
                        <svg
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 1.49344L10.0433 5.00158C10.1846 5.24418 10.4214 5.41621 10.6958 5.47563L14.6636 6.33486L11.9586 9.36223C11.7715 9.57158 11.6811 9.84993 11.7094 10.1293L12.1184 14.1684L8.40325 12.5313C8.14634 12.4181 7.85366 12.4181 7.59675 12.5313L3.88165 14.1684L4.29061 10.1293C4.31889 9.84993 4.22845 9.57158 4.04139 9.36223L1.33637 6.33486L5.30422 5.47563C5.57862 5.41621 5.81539 5.24418 5.95669 5.00158L8 1.49344Z"
                            fill="#FFD704"
                            stroke="#C7BA89"
                          ></path>
                        </svg>
                        <span> 4.7 </span>
                      </div>
                    </div>
                    <div class="logo" style="background-color: #ffffff">
                      <img
                        src="../assets/betonline-sports-review-logo.png"
                        alt="BetOnline Sportsbook logo"
                        width="130"
                        height="165"
                      />
                    </div>
                    <div class="bonus">
                      <span class="title">{{ $t("app_0066") }}</span>
                      <span class="offer">50% {{ $t("app_0067") }} $1000</span>
                      <div class="cta">
                        <a
                          href="https://t89ll.com"
                          target="_blank"
                          rel="nofollow"
                          class="cta"
                          >{{ $t("app_0076") }}</a
                        >
                      </div>
                      <div class="box-item__certified">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.66667 11.3334L4 8.66675L4.94 7.72675L6.66667 9.44675L11.06 5.05341L12 6.00008M8 0.666748L2 3.33341V7.33342C2 11.0334 4.56 14.4934 8 15.3334C11.44 14.4934 14 11.0334 14 7.33342V3.33341L8 0.666748Z"
                            fill="#C7BA89"
                          ></path>
                        </svg>
                        <span>{{ $t("app_0068") }}</span>
                      </div>
                    </div>
                    <div class="tc-apply">
                      <span class="med-title">T&amp;{{ $t("app_0069") }}</span>
                      <span class="sm-title"> 9620 {{ $t("app_0070") }} </span>
                    </div>
                    <div class="box-item__more">
                      <p class="box-item__more_btn">{{ $t("app_0071") }}</p>
                      <div class="box-item__more-content">
                        <div class="box-item__list custom-list">
                          <ul>
                            <li>
                              <i class="el-icon-success"></i>
                              <p>{{ $t("app_0072") }}</p>
                            </li>
                            <li>
                              <i class="el-icon-success"></i>
                              <p>{{ $t("app_0073") }}</p>
                            </li>
                            <li>
                              <i class="el-icon-success"></i>
                              <p>{{ $t("app_0074") }}</p>
                            </li>
                          </ul>
                        </div>
                        <a
                          class="box-item__link read-review-btn"
                          href="https://t89ll.com"
                          target="_blank"
                          >{{ $t("app_0075") }}</a
                        >
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col class="order-2" :md="8">
                  <div class="box-item box-item-modern">
                    <div class="rank">
                      <span>#2</span>
                    </div>
                    <div class="box-item__rating_flag">
                      <div class="box-item__flag">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_3385_71801)">
                            <path
                              d="M12 24.5C18.6274 24.5 24 19.1274 24 12.5C24 5.87258 18.6274 0.5 12 0.5C5.37258 0.5 0 5.87258 0 12.5C0 19.1274 5.37258 24.5 12 24.5Z"
                              fill="#F0F0F0"
                            ></path>
                            <path
                              d="M11.4805 12.5001H24.0022C24.0022 11.417 23.8578 10.3677 23.5888 9.36963H11.4805V12.5001Z"
                              fill="#D80027"
                            ></path>
                            <path
                              d="M11.4805 6.23934H22.2409C21.5063 5.04065 20.5671 3.98114 19.472 3.10889H11.4805V6.23934Z"
                              fill="#D80027"
                            ></path>
                            <path
                              d="M12.0011 24.5003C14.8252 24.5003 17.421 23.5242 19.4709 21.8916H4.53125C6.58109 23.5242 9.17689 24.5003 12.0011 24.5003Z"
                              fill="#D80027"
                            ></path>
                            <path
                              d="M1.76195 18.7608H22.2394C22.8291 17.7985 23.2865 16.7467 23.5873 15.6304H0.414062C0.714859 16.7467 1.17222 17.7985 1.76195 18.7608V18.7608Z"
                              fill="#D80027"
                            ></path>
                            <path
                              d="M5.55862 2.37397H6.65217L5.63498 3.11295L6.02353 4.30869L5.00639 3.5697L3.98925 4.30869L4.32487 3.2757C3.42928 4.02172 2.64431 4.89575 1.99744 5.86963H2.34783L1.70034 6.34002C1.59947 6.5083 1.50272 6.67925 1.41 6.85273L1.71919 7.80434L1.14234 7.38523C0.998953 7.68903 0.867797 7.99967 0.749906 8.31678L1.09055 9.36528H2.34783L1.33064 10.1043L1.71919 11.3L0.702047 10.561L0.0927656 11.0037C0.0317812 11.4939 0 11.9932 0 12.5H12C12 5.87262 12 5.09131 12 0.5C9.62944 0.5 7.41961 1.18766 5.55862 2.37397V2.37397ZM6.02353 11.3L5.00639 10.561L3.98925 11.3L4.3778 10.1043L3.36061 9.36528H4.61789L5.00639 8.16955L5.39489 9.36528H6.65217L5.63498 10.1043L6.02353 11.3ZM5.63498 6.60861L6.02353 7.80434L5.00639 7.06536L3.98925 7.80434L4.3778 6.60861L3.36061 5.86963H4.61789L5.00639 4.67389L5.39489 5.86963H6.65217L5.63498 6.60861ZM10.3279 11.3L9.31073 10.561L8.29359 11.3L8.68214 10.1043L7.66495 9.36528H8.92223L9.31073 8.16955L9.69923 9.36528H10.9565L9.93933 10.1043L10.3279 11.3ZM9.93933 6.60861L10.3279 7.80434L9.31073 7.06536L8.29359 7.80434L8.68214 6.60861L7.66495 5.86963H8.92223L9.31073 4.67389L9.69923 5.86963H10.9565L9.93933 6.60861ZM9.93933 3.11295L10.3279 4.30869L9.31073 3.5697L8.29359 4.30869L8.68214 3.11295L7.66495 2.37397H8.92223L9.31073 1.17823L9.69923 2.37397H10.9565L9.93933 3.11295Z"
                              fill="#0052B4"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_3385_71801">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0 0.5)"
                              ></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="box-item__rating">
                        <svg
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 1.49344L10.0433 5.00158C10.1846 5.24418 10.4214 5.41621 10.6958 5.47563L14.6636 6.33486L11.9586 9.36223C11.7715 9.57158 11.6811 9.84993 11.7094 10.1293L12.1184 14.1684L8.40325 12.5313C8.14634 12.4181 7.85366 12.4181 7.59675 12.5313L3.88165 14.1684L4.29061 10.1293C4.31889 9.84993 4.22845 9.57158 4.04139 9.36223L1.33637 6.33486L5.30422 5.47563C5.57862 5.41621 5.81539 5.24418 5.95669 5.00158L8 1.49344Z"
                            fill="#FFD704"
                            stroke="#C7BA89"
                          ></path>
                        </svg>
                        <span> 4.5 </span>
                      </div>
                    </div>
                    <div class="logo" style="background-color: #223e71">
                      <img
                        src="../assets/betus-sports-review-logo.png"
                        alt="BetUS Sportsbook logo"
                        width="130"
                        height="165"
                      />
                    </div>
                    <div class="bonus">
                      <span class="title">{{ $t("app_0066") }}</span>
                      <span class="offer">125% {{ $t("app_0067") }} $2500</span>
                      <div class="cta">
                        <a
                          href="https://t89ll.com"
                          target="_blank"
                          rel="nofollow"
                          class="cta"
                          >{{ $t("app_0076") }}</a
                        >
                      </div>
                      <div class="box-item__certified">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.66667 11.3334L4 8.66675L4.94 7.72675L6.66667 9.44675L11.06 5.05341L12 6.00008M8 0.666748L2 3.33341V7.33342C2 11.0334 4.56 14.4934 8 15.3334C11.44 14.4934 14 11.0334 14 7.33342V3.33341L8 0.666748Z"
                            fill="#C7BA89"
                          ></path>
                        </svg>
                        <span>{{ $t("app_0068") }}</span>
                      </div>
                    </div>
                    <div class="tc-apply">
                      <span class="med-title">T&amp;{{ $t("app_0069") }}</span>
                      <span class="sm-title"> 8269 {{ $t("app_0070") }} </span>
                    </div>
                    <div class="box-item__more show-more-js">
                      <p class="box-item__more_btn">{{ $t("app_0071") }}</p>
                      <div class="box-item__more-content">
                        <div class="box-item__list custom-list">
                          <ul>
                            <li>
                              <i class="el-icon-success"></i>
                              <p>{{ $t("app_0078") }}</p>
                            </li>
                            <li>
                              <i class="el-icon-success"></i>
                              <p>{{ $t("app_0079") }}</p>
                            </li>
                            <li>
                              <i class="el-icon-success"></i>
                              <p>{{ $t("app_0080") }}</p>
                            </li>
                          </ul>
                        </div>
                        <a
                          class="box-item__link read-review-btn"
                          href="https://t89ll.com"
                          target="_blank"
                          >{{ $t("app_0075") }}</a
                        >
                      </div>
                    </div>
                  </div>
                </el-col>
                <el-col class="order-3" :md="8">
                  <div class="box-item box-item-modern">
                    <div class="rank">
                      <span>#3</span>
                    </div>
                    <div class="box-item__rating_flag">
                      <div class="box-item__flag">
                        <svg
                          width="24"
                          height="25"
                          viewBox="0 0 24 25"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <g clip-path="url(#clip0_3385_71801)">
                            <path
                              d="M12 24.5C18.6274 24.5 24 19.1274 24 12.5C24 5.87258 18.6274 0.5 12 0.5C5.37258 0.5 0 5.87258 0 12.5C0 19.1274 5.37258 24.5 12 24.5Z"
                              fill="#F0F0F0"
                            ></path>
                            <path
                              d="M11.4805 12.5001H24.0022C24.0022 11.417 23.8578 10.3677 23.5888 9.36963H11.4805V12.5001Z"
                              fill="#D80027"
                            ></path>
                            <path
                              d="M11.4805 6.23934H22.2409C21.5063 5.04065 20.5671 3.98114 19.472 3.10889H11.4805V6.23934Z"
                              fill="#D80027"
                            ></path>
                            <path
                              d="M12.0011 24.5003C14.8252 24.5003 17.421 23.5242 19.4709 21.8916H4.53125C6.58109 23.5242 9.17689 24.5003 12.0011 24.5003Z"
                              fill="#D80027"
                            ></path>
                            <path
                              d="M1.76195 18.7608H22.2394C22.8291 17.7985 23.2865 16.7467 23.5873 15.6304H0.414062C0.714859 16.7467 1.17222 17.7985 1.76195 18.7608V18.7608Z"
                              fill="#D80027"
                            ></path>
                            <path
                              d="M5.55862 2.37397H6.65217L5.63498 3.11295L6.02353 4.30869L5.00639 3.5697L3.98925 4.30869L4.32487 3.2757C3.42928 4.02172 2.64431 4.89575 1.99744 5.86963H2.34783L1.70034 6.34002C1.59947 6.5083 1.50272 6.67925 1.41 6.85273L1.71919 7.80434L1.14234 7.38523C0.998953 7.68903 0.867797 7.99967 0.749906 8.31678L1.09055 9.36528H2.34783L1.33064 10.1043L1.71919 11.3L0.702047 10.561L0.0927656 11.0037C0.0317812 11.4939 0 11.9932 0 12.5H12C12 5.87262 12 5.09131 12 0.5C9.62944 0.5 7.41961 1.18766 5.55862 2.37397V2.37397ZM6.02353 11.3L5.00639 10.561L3.98925 11.3L4.3778 10.1043L3.36061 9.36528H4.61789L5.00639 8.16955L5.39489 9.36528H6.65217L5.63498 10.1043L6.02353 11.3ZM5.63498 6.60861L6.02353 7.80434L5.00639 7.06536L3.98925 7.80434L4.3778 6.60861L3.36061 5.86963H4.61789L5.00639 4.67389L5.39489 5.86963H6.65217L5.63498 6.60861ZM10.3279 11.3L9.31073 10.561L8.29359 11.3L8.68214 10.1043L7.66495 9.36528H8.92223L9.31073 8.16955L9.69923 9.36528H10.9565L9.93933 10.1043L10.3279 11.3ZM9.93933 6.60861L10.3279 7.80434L9.31073 7.06536L8.29359 7.80434L8.68214 6.60861L7.66495 5.86963H8.92223L9.31073 4.67389L9.69923 5.86963H10.9565L9.93933 6.60861ZM9.93933 3.11295L10.3279 4.30869L9.31073 3.5697L8.29359 4.30869L8.68214 3.11295L7.66495 2.37397H8.92223L9.31073 1.17823L9.69923 2.37397H10.9565L9.93933 3.11295Z"
                              fill="#0052B4"
                            ></path>
                          </g>
                          <defs>
                            <clipPath id="clip0_3385_71801">
                              <rect
                                width="24"
                                height="24"
                                fill="white"
                                transform="translate(0 0.5)"
                              ></rect>
                            </clipPath>
                          </defs>
                        </svg>
                      </div>
                      <div class="box-item__rating">
                        <svg
                          width="16"
                          height="15"
                          viewBox="0 0 16 15"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M8 1.49344L10.0433 5.00158C10.1846 5.24418 10.4214 5.41621 10.6958 5.47563L14.6636 6.33486L11.9586 9.36223C11.7715 9.57158 11.6811 9.84993 11.7094 10.1293L12.1184 14.1684L8.40325 12.5313C8.14634 12.4181 7.85366 12.4181 7.59675 12.5313L3.88165 14.1684L4.29061 10.1293C4.31889 9.84993 4.22845 9.57158 4.04139 9.36223L1.33637 6.33486L5.30422 5.47563C5.57862 5.41621 5.81539 5.24418 5.95669 5.00158L8 1.49344Z"
                            fill="#FFD704"
                            stroke="#C7BA89"
                          ></path>
                        </svg>
                        <span> 4.6 </span>
                      </div>
                    </div>
                    <div class="logo" style="background-color: #224162">
                      <img
                        src="../assets/sportsbetting-ag-sports-review-logo.png"
                        alt="SportsBetting.ag Sportsbook logo"
                        width="130"
                        height="165"
                      />
                    </div>
                    <div class="bonus">
                      <span class="title">{{ $t("app_0066") }}</span>
                      <span class="offer">50% {{ $t("app_0067") }} $1000</span>
                      <div class="cta">
                        <a
                          href="https://t89ll.com"
                          target="_blank"
                          rel="nofollow"
                          class="cta"
                          >{{ $t("app_0076") }}</a
                        >
                      </div>
                      <div class="box-item__certified">
                        <svg
                          width="16"
                          height="16"
                          viewBox="0 0 16 16"
                          fill="none"
                          xmlns="http://www.w3.org/2000/svg"
                        >
                          <path
                            d="M6.66667 11.3334L4 8.66675L4.94 7.72675L6.66667 9.44675L11.06 5.05341L12 6.00008M8 0.666748L2 3.33341V7.33342C2 11.0334 4.56 14.4934 8 15.3334C11.44 14.4934 14 11.0334 14 7.33342V3.33341L8 0.666748Z"
                            fill="#C7BA89"
                          ></path>
                        </svg>
                        <span>{{ $t("app_0068") }}</span>
                      </div>
                    </div>
                    <div class="tc-apply">
                      <span class="med-title">T&amp;{{ $t("app_0069") }}</span>
                      <span class="sm-title"> 9458 {{ $t("app_0070") }} </span>
                    </div>
                    <div class="box-item__more show-more-js">
                      <p class="box-item__more_btn">{{ $t("app_0071") }}</p>
                      <div class="box-item__more-content">
                        <div class="box-item__list custom-list">
                          <ul>
                            <li>
                              <i class="el-icon-success"></i>
                              <p>{{ $t("app_0077") }}</p>
                            </li>
                            <li>
                              <i class="el-icon-success"></i>
                              <p>{{ $t("app_0081") }}</p>
                            </li>
                            <li>
                              <i class="el-icon-success"></i>
                              <p>{{ $t("app_0082") }}</p>
                            </li>
                          </ul>
                        </div>
                        <a
                          class="box-item__link read-review-btn"
                          href="https://t89ll.com"
                          target="_blank"
                          >{{ $t("app_0075") }}</a
                        >
                      </div>
                    </div>
                  </div>
                </el-col>
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </section>
    <section class="false-headline">
      <div class="container">
        <el-col class="" :xs="20" :sm="20" :md="20" :push="2" :pull="2">
          <h2 class="head">
            {{ $t("app_0086") }} <b>{{ $t("app_0087") }}</b>
          </h2>
          <span class="description">
            <p>
              {{ $t("app_0088") }}
            </p>
            <p>
              {{ $t("app_0089") }}
            </p>
            <p>
              {{ $t("app_0090") }}
            </p>
            <h3>{{ $t("app_0091") }}</h3>
            <p>
              {{ $t("app_0092") }}
            </p>
            <h3>{{ $t("app_0093") }}</h3>
            <p>
              {{ $t("app_0094") }}
            </p>
            <h3>{{ $t("app_0095") }}</h3>
            <p>
              {{ $t("app_0096") }}
            </p>
            <h3>{{ $t("app_0097") }}</h3>
            <p>
              {{ $t("app_0098") }}
            </p>
            <h3>{{ $t("app_0099") }}</h3>
            <p>
              {{ $t("app_0100") }}
            </p>
            <h3>{{ $t("app_0101") }}</h3>
            <p>
              {{ $t("app_0102") }}
            </p>
            <h3>{{ $t("app_0103") }}</h3>
            <p>
              {{ $t("app_0104") }}
            </p>
            <h3>{{ $t("app_0105") }}</h3>
            <p>
              {{ $t("app_0106") }}
            </p>
            <h3>{{ $t("app_0107") }}</h3>
            <p>
              {{ $t("app_0108") }}
            </p>
            <h3>{{ $t("app_0109") }}</h3>
            <p>
              {{ $t("app_0110") }}
            </p>
          </span>
        </el-col>
      </div>
    </section>
    <section class="most-recommended-casinos">
      <div class="container">
        <el-row class="row">
          <el-col class="" :md="24">
            <span class="head">{{ $t("app_0112") }}</span>
          </el-col>
          <el-col class="" :md="8">
            <div class="box-item">
              <div class="logo" style="background-color: #0a0a0a">
                <img
                  src="../assets/bovada-sports-review-logo.png"
                  alt="Bovada Sportsbook logo"
                />
              </div>
              <div class="offer">50% {{ $t("app_0067") }} $250</div>
              <div class="description">
                <p>
                  {{ $t("app_0113") }}
                </p>
              </div>
              <div class="functions">
                <a href="https://t89ll.com" target="_blank" class="cta">{{
                  $t("app_0114")
                }}</a>
                <a
                  href="https://t89ll.com"
                  target="_blank"
                  rel="nofollow"
                  class="visit"
                  >{{ $t("app_0111") }}</a
                >
              </div>
            </div>
          </el-col>
          <el-col class="" :md="8">
            <div class="box-item">
              <div class="logo" style="background-color: #efefef">
                <img
                  src="../assets/mybookie-sports-review-logo.png"
                  alt="MyBookie Sportsbook logo"
                />
              </div>
              <div class="offer">100% {{ $t("app_0067") }} $1000</div>
              <div class="description">
                <p>
                  {{ $t("app_0115") }}
                </p>
              </div>
              <div class="functions">
                <a href="https://t89ll.com" target="_blank" class="cta">{{
                  $t("app_0114")
                }}</a>
                <a
                  href="https://t89ll.com"
                  target="_blank"
                  rel="nofollow"
                  class="visit"
                  >{{ $t("app_0111") }}</a
                >
              </div>
            </div>
          </el-col>
          <el-col class="" :md="8">
            <div class="box-item">
              <div class="logo" style="background-color: #000033">
                <img
                  src="../assets/xbet-sports-review-logo.png"
                  alt="XBet Sportsbook logo"
                />
              </div>
              <div class="offer">50% {{ $t("app_0067") }} $300</div>
              <div class="description">
                <p>
                  {{ $t("app_0116") }}
                </p>
              </div>
              <div class="functions">
                <a href="https://t89ll.com" target="_blank" class="cta">{{
                  $t("app_0114")
                }}</a>
                <a
                  href="https://t89ll.com"
                  target="_blank"
                  rel="nofollow"
                  class="visit"
                  >{{ $t("app_0111") }}</a
                >
              </div>
            </div>
          </el-col>
        </el-row>
      </div>
    </section>
    <section class="false-headline">
      <div class="container">
        <el-col class="" :xs="20" :sm="20" :md="20" :push="2" :pull="2">
          <h2 class="head">
            {{ $t("app_0221") }} <b>{{ $t("app_0220") }}</b>
          </h2>
          <span class="description">
            <p>
              {{ $t("app_0117") }}
            </p>
            <ol>
              <li>
                <strong>{{ $t("app_0118") }}</strong
                >{{ $t("app_0119") }}
              </li>
              <li>
                <strong>{{ $t("app_0120") }}</strong
                >{{ $t("app_0121") }}
              </li>
              <li>
                <strong>{{ $t("app_0123") }}</strong
                >{{ $t("app_0124") }}
              </li>
              <li>
                <strong>{{ $t("app_0125") }}</strong
                >{{ $t("app_0126") }}
              </li>
              <li>
                <strong>{{ $t("app_0127") }}</strong
                >{{ $t("app_0128") }}
              </li>
            </ol>
          </span>
        </el-col>
      </div>
    </section>
    <section class="false-headline">
      <div class="container">
        <el-col class="" :xs="20" :sm="20" :md="20" :push="2" :pull="2">
          <h2 class="head">
            {{ $t("app_0129") }} <b>{{ $t("app_0087") }}</b>
          </h2>
          <span class="description">
            <p>
              {{ $t("app_0130") }}
            </p>
            <p>
              {{ $t("app_0131") }}
            </p>
            <p>
              {{ $t("app_0132") }}
            </p>
            <p>
              {{ $t("app_0133") }}
            </p>
          </span>
        </el-col>
      </div>
    </section>
    <section class="false-headline">
      <div class="container">
        <el-col class="" :xs="20" :sm="20" :md="20" :push="2" :pull="2">
          <h2 class="head">{{ $t("app_0134") }}</h2>
          <span class="description">
            <section class="latest-posts">
              <div class="post-cards horizontal">
                <div
                  class="item"
                  v-for="(item, index) of articleList"
                  :key="index"
                >
                  <div class="data">
                    <div class="bettingSites-info">
                      <span class="category"
                        ><a
                          href="javascript:;"
                          target="_blank"
                          @click="newCategory(item.CategoryId)"
                          >{{ $t("app_0135") }}</a
                        ></span
                      >
                      <span class="date">{{
                        item.AddTime | detailsFormatDate
                      }}</span>
                    </div>
                    <a
                      href="javascript:;"
                      target="_blank"
                      @click="detailsNewWidow(item.Id)"
                    >
                      <div class="heading">
                        {{ item.SeoTitle }}
                      </div>
                      <div class="title">
                        <span>{{ item.Zhaiyao }}</span>
                      </div>
                    </a>
                    <div class="author">
                      <span>{{ item.Author }}</span>
                    </div>
                  </div>
                  <div class="thumbnail">
                    <a
                      href="javascript:;"
                      target="_blank"
                      @click="detailsNewWidow(item.Id)"
                    >
                      <img :src="item.ImgUrl" alt="" />
                    </a>
                  </div>
                </div>
              </div>
            </section>
          </span>
        </el-col>
      </div>
    </section>
    <section class="false-headline">
      <div class="container">
        <el-col class="" :xs="20" :sm="20" :md="20" :push="2" :pull="2">
          <h2 class="head">
            {{ $t("app_0145") }} <b>{{ $t("app_0146") }}</b>
          </h2>
          <span class="description">
            <p>
              {{ $t("app_0147") }}
            </p>
            <p>
              {{ $t("app_0148") }}
            </p>
            <p>
              {{ $t("app_0149") }}
            </p>
          </span>
        </el-col>
      </div>
    </section>
    <section class="false-headline">
      <div class="container">
        <el-col class="" :xs="20" :sm="20" :md="20" :push="2" :pull="2">
          <h2 class="head">
            {{ $t("app_0150") }} <b>{{ $t("app_0151") }}</b>
          </h2>
          <span class="description">
            <p>
              {{ $t("app_0152") }}
            </p>
            <p>
              {{ $t("app_0153") }}
            </p>
            <h3>{{ $t("app_0154") }}</h3>
            <p>
              {{ $t("app_0155") }}
            </p>
            <h3>{{ $t("app_0156") }}</h3>
            <p>
              {{ $t("app_0157") }}
            </p>
            <h3>{{ $t("app_0158") }}</h3>
            <p>
              {{ $t("app_0159") }}
            </p>
          </span>
        </el-col>
      </div>
    </section>
    <section class="false-headline">
      <div class="container">
        <el-col class="" :xs="20" :sm="20" :md="20" :push="2" :pull="2">
          <h2 class="head">
            {{ $t("app_0160") }} <b>{{ $t("app_0087") }}</b>
          </h2>
          <span class="description">
            <p>
              {{ $t("app_0161") }}
            </p>
            <p>
              {{ $t("app_0162") }}
            </p>
            <p>
              {{ $t("app_0163") }}
            </p>
          </span>
        </el-col>
      </div>
    </section>
    <section class="false-headline">
      <div class="container">
        <el-col class="" :xs="20" :sm="20" :md="20" :push="2" :pull="2">
          <h2 class="head">
            {{ $t("app_0219") }} <b>{{ $t("app_0165") }}</b
            >?
          </h2>
          <span class="description">
            <p>
              {{ $t("app_0166") }}
            </p>
            <p>
              {{ $t("app_0167") }}
            </p>
            <p>
              {{ $t("app_0168") }}
            </p>
          </span>
        </el-col>
      </div>
    </section>
    <section class="false-headline">
      <div class="container">
        <el-col class="" :xs="20" :sm="20" :md="20" :push="2" :pull="2">
          <h2 class="head">
            {{ $t("app_0169") }}
          </h2>
          <span class="description">
            <p>
              {{ $t("app_0170") }}
            </p>
            <p>
              <strong>{{ $t("app_0171") }}</strong>
            </p>
            <ul>
              <li>
                {{ $t("app_0172") }}
              </li>
              <li>
                {{ $t("app_0173") }}
              </li>
            </ul>
            <p>
              <strong>{{ $t("app_0174") }}</strong>
            </p>
            <ul>
              <li>
                {{ $t("app_0175") }}
              </li>
              <li>
                {{ $t("app_0176") }}
              </li>
              <li>
                {{ $t("app_0177") }}
              </li>
            </ul>
            <p>
              <strong>{{ $t("app_0178") }}</strong>
            </p>
            <ul>
              <li>
                {{ $t("app_0179") }}
              </li>
              <li>
                {{ $t("app_0180") }}
              </li>
              <li>
                {{ $t("app_0181") }}
              </li>
            </ul>
            <p>
              <strong>{{ $t("app_0182") }}</strong>
            </p>
            <ul>
              <li>
                {{ $t("app_0183") }}
              </li>
              <li>
                {{ $t("app_0184") }}
              </li>
              <li>
                {{ $t("app_0185") }}
              </li>
            </ul>
            <p>
              <strong>{{ $t("app_0186") }}&nbsp;</strong>
            </p>
            <ul>
              <li>
                {{ $t("app_0187") }}
              </li>
              <li>
                {{ $t("app_0188") }}
              </li>
              <li>
                {{ $t("app_0189") }}
              </li>
            </ul>
          </span>
        </el-col>
      </div>
    </section>
    <section class="how-we-review">
      <div class="container">
        <span class="head"
          >{{ $t("app_0190") }} <b>{{ $t("app_0191") }}</b>
          {{ $t("app_0192") }}</span
        >
        <div class="wrapper">
          <div class="seperator w-image">
            <div class="left">
              <span class="badge">
                <i class="el-icon-check"></i>
                {{ $t("app_0193") }}</span
              >
              <span class="description">
                <p>
                  {{ $t("app_0194") }}
                </p>
              </span>
            </div>
            <div class="right">
              <div class="image">
                <img src="../assets/safety-check.png" alt="Poster" />
              </div>
            </div>
          </div>
          <div class="seperator">
            <div class="left">
              <span class="badge"
                ><i class="el-icon-check"></i>{{ $t("app_0195") }}</span
              >
              <span class="description">
                <p>
                  {{ $t("app_0196") }}
                </p>
              </span>
            </div>
            <div class="right">
              <div class="image">
                <img src="../assets/rmg-second.png" alt="Poster" />
              </div>
            </div>
          </div>
          <div class="seperator">
            <div class="left">
              <span class="badge"
                ><i class="el-icon-check"></i>{{ $t("app_0197") }}</span
              >
              <span class="description">
                <p>
                  {{ $t("app_0198") }}
                </p>
              </span>
            </div>
            <div class="right">
              <div class="image">
                <img src="../assets/rmg-third.png" alt="Poster" />
              </div>
            </div>
          </div>
          <div class="seperator">
            <div class="left">
              <span class="badge"
                ><i class="el-icon-check"></i>{{ $t("app_0199") }}</span
              >
              <span class="description">
                <p>
                  {{ $t("app_0200") }}
                </p>
              </span>
            </div>
            <div class="right">
              <div class="image">
                <img src="../assets/rmg-fourth.png" alt="Poster" />
              </div>
            </div>
          </div>
          <div class="seperator">
            <div class="left">
              <span class="badge"
                ><i class="el-icon-check"></i>{{ $t("app_0201") }}</span
              >
              <span class="description">
                <p>
                  {{ $t("app_0203") }}
                </p>
                <p>
                  {{ $t("app_0204") }}
                </p>
              </span>
            </div>
            <div class="right">
              <div class="image">
                <img src="../assets/rmg-fifth.png" alt="Poster" />
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section class="faq">
      <div class="container">
        <div class="box">
          <h3>{{ $t("app_0205") }}</h3>
          <div class="content">
            <div class="faq-block">
              <el-collapse accordion>
                <el-collapse-item>
                  <template slot="title">
                    {{ $t("app_0206") }}
                  </template>
                  <div>
                    {{ $t("app_0207") }}
                  </div>
                </el-collapse-item>
                <el-collapse-item :title="this.$t('app_0208')">
                  <div>
                    {{ $t("app_0209") }}
                  </div>
                </el-collapse-item>
                <el-collapse-item :title="this.$t('app_0210')">
                  <div>
                    {{ $t("app_0211") }}
                  </div>
                </el-collapse-item>
                <el-collapse-item :title="this.$t('app_0212')">
                  <div>
                    {{ $t("app_0213") }}
                  </div>
                </el-collapse-item>
                <el-collapse-item :title="this.$t('app_0214')">
                  <div>
                    {{ $t("app_0215") }}
                  </div>
                </el-collapse-item>
              </el-collapse>
            </div>
          </div>
          <div class="contact">
            <span class="head">{{ $t("app_0216") }}</span>
            <span class="title">{{ $t("app_0217") }}</span>
            <div class="cta">
              <a href="">{{ $t("app_0218") }}</a>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
import { getArticleList } from "@/axios/api";
export default {
  name: "BettingSites",
  props: {},
  data() {
    return {
      params: {
        CategoryId: "3",
        IdFrom: 0,
        IdTo: 5,
      },
      articleList: [],
    };
  },
  mounted() {
    this.getArticleList();
  },
  methods: {
    async getArticleList() {
      const res = await getArticleList(this.params);
      this.articleList.push(...res.data);
    },
    detailsNewWidow(Id) {
      console.log(Id);
      debugger;
      if (Id) {
        window.open(window.location.origin + "/news/" + Id);
      }
    },
    newCategory(Id) {
      debugger;
      if (Id) {
        window.open(window.location.origin + "/" + Id);
      }
    },
  },
};
</script>
<style lang="scss">
.betting-sites {
  margin-top: 50px;
}
.best-online-casinos {
  width: 100%;
  position: relative;
  margin: 20px 0 60px 0;
  clear: both;
  .container {
    position: relative;
    margin-left: auto;
    margin-right: auto;
    .row {
      display: -webkit-box;
      display: -webkit-flex;
      display: -ms-flexbox;
      display: flex;
      -webkit-flex-wrap: wrap;
      -ms-flex-wrap: wrap;
      flex-wrap: wrap;
      .el-col-md-8 {
        display: flex;
        justify-content: center;
      }
      .order-1 {
        order: 1;
      }
      .order-2 {
        order: 2;
        margin: 72px 0 0 0;
      }
      .order-3 {
        order: 3;
        margin: 72px 0 0 0;
      }
      .best-online-casinos .hero-default {
        margin-top: 40px;
      }
      .hero {
        width: 100%;
        /* float: left; */
        position: relative;
        margin: 0 auto;
        display: inline-block;
        text-align: center;
        .head {
          width: 100%;
          padding: 0;
          color: #1d3b69;
          font-size: 30px;
          font-weight: 600;
          line-height: 1.4;
          margin: 0 0 24px 0;
          text-align: center;
          b {
            font-style: italic;
            color: #c7ba89;
            font-family: "Merriweather", sans-serif;
          }
          p {
            width: 100%;
            font-size: 16px;
            line-height: 1.7;
            color: #1d3b69;
            margin-bottom: 0;
          }
        }
      }
    }
  }
  .tab-content {
    display: block;
    width: 100%;
    position: relative;
    margin-top: 15px;
  }
  .box-item {
    // width: 100%;
    float: left;
    position: relative;
    border-radius: 16px;
    background-color: #ebebeb;
    margin: 50px 0 0 0;
    padding: 68px 40px 50px 40px;
    .rank {
      z-index: 200;
      font-size: 14px;
      line-height: 1.2;
      font-weight: 600;
      color: #1d3b69;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 72px;
      height: 84px;
      position: absolute;
      left: 50%;
      top: 0;
      transform: translate(-50%, -58%);
      span {
        position: absolute;
        z-index: 100;
        top: calc(50% - 4px);
        left: 50%;
        transform: translateX(-50%);
      }
    }
    .box-item__rating_flag {
      position: absolute;
      display: flex;
      width: calc(100% - 40px);
      align-items: center;
      justify-content: space-between;
      top: 26px;
      left: 50%;
      transform: translateX(-50%);
    }
    .logo,
    .brand-block .logo {
      width: 180px;
      height: 70px;
      line-height: 70px;
      white-space: nowrap;
      text-align: center;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 10px auto;
      border-radius: 8px;
      img {
        width: 100%;
        max-width: 130px;
        background-size: 100%;
        background-repeat: no-repeat;
        vertical-align: middle;
        text-align: center;
        display: inline-block;
        height: auto;
      }
    }
    .bonus {
      float: left;
      border-radius: 12px;
      background-color: #fff;
      padding: 20px;
      margin: 28px 0 16px 0;
      span.title {
        width: 100%;
        float: left;
        text-align: center;
        font-size: 13px;
        text-transform: uppercase;
        color: #8e8f9d;
        line-height: 14px;
        font-weight: 600;
      }
      span.offer,
      .brand-block .offer {
        width: 100%;
        float: left;
        text-align: center;
        font-size: 22px;
        color: #44b456;
        line-height: 28px;
        font-weight: 600;
        margin: 8px 0 16px 0;
      }
      .cta {
        width: 100%;
        float: left;
        a,
        .brand-block .cta {
          width: 100%;
          height: 48px;
          line-height: 48px;
          float: left;
          display: inline-block;
          border-radius: 7px;
          text-align: center;
          background-color: #44b456;
          color: #fff;
          font-size: 16px;
          font-weight: 600;
          transition: 0.2s linear;
          text-decoration: none;
        }
      }
      .box-item__certified {
        text-align: center;
        margin: 12px auto 0;
      }
    }
    .box-item__more {
      .box-item__more_btn {
        display: none;
        position: relative;
        max-width: fit-content;
        padding-right: 24px;
        margin: 0 auto;
        font-weight: 600;
        font-size: 14px;
        line-height: 17px;
        text-align: center;
        color: #1d3b69;
        cursor: pointer;
      }
      .box-item__list {
        border-top: 1px solid #dbdce0;
        border-bottom: 1px solid #dbdce0;
        padding: 16px 0;
        margin-bottom: 16px;
      }
      .custom-list {
        position: relative;
        display: inline-block;
        width: 100%;
        ul {
          padding: 0;
          li {
            position: relative;
            list-style: none;
            margin: 0;
            //padding: 0 0 0 0;
            font-family: "Inter", sans-serif;
            font-weight: 400;
            font-size: 12px;
            line-height: 1.2;
            letter-spacing: 0.4px;
            color: #334250;
            display: flex;
            max-width: 254px;
            i {
              color: #44b456;
              font-size: 14px;
              margin-right: 10px;
            }
          }
          li:not(:last-child) {
            margin-bottom: 12px;
          }
          p {
            word-break: break-all;
            margin: 0;
            padding: 0;
          }
        }
      }
      .box-item__link {
        font-weight: 600;
        font-size: 14px;
        color: #1d3b69;
      }
      .read-review-btn {
        position: relative;
        display: block;
        max-width: fit-content;
        margin: 0 auto;
        transition: all 0.3s ease;
        line-height: 20px;
        &::before {
          content: "";
          position: absolute;
          bottom: 0;
          left: 50%;
          width: 100%;
          display: block;
          height: 1px;
          background-color: #334250;
          transition: all 0.4s;
          transform: translate(-50%);
        }
        &:hover::before {
          width: 0;
        }
      }
      a {
        text-decoration: none;
      }
    }
  }
  .box-item-modern {
    .box-top {
      position: absolute;
      width: 100%;
      top: 0;
      left: 0;
      background-color: #c7ba89;
      border-radius: 10px 10px 0 0;
      padding: 30px 0px 8px;
      color: #fff;
      text-align: center;
      font-weight: 600;
      font-size: 14px;
    }
    .bonus {
      float: none;
      display: flex;
      flex-direction: column;
    }
    .tc-apply {
      margin-bottom: 16px;
      float: none;
      display: flex;
      flex-direction: column;
      width: 100%;
      float: left;
      text-align: center;
      span.med-title,
      .brand-block .sm-title {
        font-size: 14px;
        line-height: 18px;
        color: #1d3b69;
        margin: 0 0 6px 0;
      }
      span,
      .brand-block .tc-apply span {
        width: 100%;
        float: left;
        text-align: center;
      }
      span.sm-title {
        font-size: 12px;
        color: #8e8f9d;
        letter-spacing: 0.4px;
        line-height: 16px;
        max-width: 250px;
      }
    }
  }
  .el-col-md-8:nth-child(1) .box-item {
    border: 3px solid #c7ba89;
    .rank {
      padding: 6px;
      transform: translate(-50%, -76%);
      &::before {
        content: "";
        background-image: url("/src/assets/rank-yellow.svg");
        width: 100%;
        height: 100%;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: 6px;
        top: 0;
        z-index: 1;
      }
      span {
        top: calc(50% - -8px);
      }
    }
    .box-item__rating_flag {
      top: 78px;
    }
  }
  .el-col-md-8:nth-child(2) .box-item {
    .rank {
      &::before {
        content: "";
        background-image: url("/src/assets/rank-blue.svg");
        width: 100%;
        height: 100%;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: 0px;
        top: 0;
        z-index: 1;
      }
    }
  }
  .el-col-md-8:nth-child(3) .box-item {
    .rank {
      &::before {
        content: "";
        background-image: url("/src/assets/rank-bronze.svg");
        width: 100%;
        height: 100%;
        display: inline-block;
        background-size: contain;
        background-repeat: no-repeat;
        position: absolute;
        left: 0px;
        top: 0;
        z-index: 1;
      }
    }
  }
}
.false-headline {
  width: 100%;
  position: relative;
  margin: 0 0 30px 0;
  display: flex;
  justify-content: center;
  .container {
    display: inline-block;
    .head {
      width: 100%;
      font-size: 26px;
      color: #1d3b69;
      font-weight: 600;
      line-height: 1.4;
      letter-spacing: -1.2px;
      margin: 0;
      padding: 0;
      b {
        font-style: italic;
        font-family: "Merriweather";
        color: #c7ba89;
      }
    }
    span.description {
      width: 100%;
      float: left;
      color: #385983;
      font-size: 16px;
      line-height: 1.7;
      margin: 15px 0 0 0;
      h3 {
        font-size: 22px !important;
        color: #334250;
        line-height: 1.4;
        position: relative;
        font-weight: 600;
        padding: 10px 0 0;
        margin: 20px 0 16px 0;
        clear: left;
      }
    }
  }
}
.most-recommended-casinos {
  span.head {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 30px;
    font-weight: 600;
    margin: 0 0 24px 0;
    color: #1d3b69;
    line-height: 1.2;
  }
  .box-item {
    //width: 100%;
    float: left;
    position: relative;
    background: rgba(248, 248, 250, 0.5);
    border: 1px solid rgba(167, 189, 210, 0.25);
    border-radius: 16px;
    padding: 32px 24px;
    margin: 0 30px 30px 30px;
    .logo {
      white-space: nowrap;
      text-align: center;
      display: block;
      border-radius: 8px;
      margin: 0 auto 20px;
      img {
        width: 50%;
        background-size: 100%;
        background-repeat: no-repeat;
        display: inline-block;
        vertical-align: middle;
        max-width: 140px;
        padding: 10px 20px;
      }
    }
    .offer {
      width: 100%;
      float: left;
      font-size: 20px;
      line-height: 28px;
      font-weight: 600;
      color: #1d3b69;
      margin: 0 0 16px 0;
      text-align: center;
    }
    .description {
      width: 100%;
      float: left;
      p {
        width: 100%;
        float: left;
        padding: 0;
        font-size: 14px;
        line-height: 1.7;
        color: #334250;
        margin: 0 0 25px 0;
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
      a {
        color: #204289;
        position: relative;
        border-bottom: 1px solid #334250;
        padding-bottom: 1px;
        font-weight: 500;
      }
    }
    .functions {
      width: 100%;
      float: left;
      position: relative;
      a {
        text-decoration: none;
      }
      a.cta {
        width: 50%;
        display: inline-block;
        height: 48px;
        line-height: 48px;
        border-radius: 7px;
        background-color: #c7ba89;
        text-align: center;
        font-size: 18px;
        font-weight: 600;
        color: #fff;
        transition: 0.3s all;
        &:hover {
          color: #204289;
        }
      }
      a.visit {
        width: 50%;
        display: inline-block;
        line-height: 48px;
        text-align: center;
        color: #204289;
        font-size: 18px;
        font-weight: 500;
        &:hover {
          text-decoration: underline;
        }
      }
    }
  }
}
.latest-posts {
  width: 100%;
  float: left;
  position: relative;
  margin-bottom: 25px;
  .post-cards {
    width: 100%;
    float: left;
    margin: 0;
    .item {
      width: 100%;
      float: left;
      position: relative;
      border-bottom: 1px solid #d0deea;
      display: flex;
      padding: 0 0 20px 0;
      margin: 0 0 20px 0;

      .data {
        width: calc(100% - 155px);
        //margin: 0 24px 0 0;
        float: left;
        padding-left: 14px;
        .bettingSites-info {
          width: 100%;
          float: left;
          margin: 0 0 12px 0;
          order: 0;
          span.category {
            position: relative;
            &::before {
              content: "";
              width: 6px;
              height: 6px;
              background: #204289;
              float: left;
              margin-right: 6px;
              transform: rotate(-45deg);
              border-radius: 1px;
              position: relative;
              top: 4px;
            }
            a {
              margin-right: 16px;
              font-weight: 600 !important;
              font-size: 13px !important;
              line-height: 15px !important;
              text-transform: uppercase !important;
              color: #204289 !important;
              border-bottom: none !important;
              padding: 0 !important;
              float: left;
              text-decoration: none;
            }
          }
          span.date {
            color: #8e8f9d;
            font-weight: 400;
            font-size: 12px;
            line-height: 15px;
            text-transform: uppercase;
            float: left;
          }
        }
        a {
          color: #204289;
          position: relative;
          border-bottom: 1px solid #334250;
          padding-bottom: 1px;
          font-weight: 500;
          text-decoration: none;
        }
        .heading {
          width: 100%;
          float: left;
          font-size: 18px;
          font-weight: 600;
          color: #334250;
          line-height: 1.2;
          margin: 0 0 16px 0;
          display: -webkit-box;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          word-break: break-word;
          min-height: inherit;
          text-align: left;
        }
        .title {
          width: 100%;
          float: left;
          color: #171f4e80;
          font-size: 14px;
          line-height: 19px;
          -webkit-line-clamp: 3;
          -webkit-box-orient: vertical;
          overflow: hidden;
          margin: 0 0 24px 0;
          display: none;
        }
        .author {
          font-size: 12px;
          width: 100%;
          float: left;
          line-height: 16px;
          color: #204289;
          display: none;
          a {
            border-bottom: none !important;
            padding: 0 !important;
            line-height: 16px !important;
            color: #204289 !important;
            font-size: 12px !important;
            text-decoration: none;
          }
        }
      }
      .thumbnail {
        width: 155px;
        height: 117px;
        overflow: hidden;
        float: left;
        order: -1;
        img {
          width: 100%;
          height: 100%;
          display: inline-block;
          background-size: 100%;
          background-repeat: no-repeat;
          object-fit: cover;
          object-position: center;
          transition: 0.6s all;
          &:hover {
            transform: scale(1.1);
          }
        }
      }
    }
  }
}
.how-we-review {
  width: 100%;
  float: left;
  position: relative;
  margin-top: 40px;
  span.head {
    width: 100%;
    float: left;
    text-align: center;
    font-size: 30px;
    color: #1d3b69;
    font-weight: 600;
    line-height: 1.4;
    letter-spacing: -1.2px;
    margin: 0 0 30px 0;
    b {
      font-style: italic;
      font-family: "Merriweather";
      color: #c7ba89;
    }
  }
  .wrapper {
    float: left;
    position: relative;
    border: 1px solid #a7bdd2;
    border-radius: 16px;
    padding: 15px 16px;
    margin: 0 16px;
    .seperator {
      width: 100%;
      float: left;
      border-bottom: 1px solid #a7bdd2;
      padding-bottom: 40px;
      margin-bottom: 40px;
      .left {
        margin-right: 30px;
        margin-bottom: 0;
        span.badge {
          height: 56px;
          border-radius: 16px;
          padding: 0 10px;
          display: inline-block;
          background: #e5e5e5;
          line-height: 56px;
          color: #1d3b69;
          font-weight: 600;
          position: relative;
          margin: 0 0 16px 0;
          font-size: 20px;
          width: 100%;
          i {
            content: "\e80e";
            width: 38px;
            height: 38px;
            line-height: 40px;
            color: #fff;
            background-color: #c7ba89;
            font-size: 18px;
            margin-right: 8px;
            text-align: center;
            border-radius: 100px;
            float: left;
            position: relative;
            top: 10px;
            display: inline-block;
            font-weight: bold;
          }
        }
        span.description {
          float: left;
          padding-left: 0px;
          p {
            width: 100%;
            float: left;
            margin: 0 0 10px 0;
            font-size: 18px;
            color: #334250;
            line-height: 1.7;
          }
        }
      }
      .right {
        display: flex;
        margin: 0 auto;
        width: 250px;
        img {
          width: 100%;
          height: 100%;
          display: inline-block;
          float: left;
          background-size: 100%;
          background-repeat: no-repeat;
        }
      }
    }
  }
}
.faq {
  width: 100%;
  float: left;
  margin-top: 80px;
  .box {
    //width: 100%;
    float: left;
    position: relative;
    background-color: #eeeef1;
    padding: 30px 15px;
    margin: 0 0 30px 0;
    h3 {
      width: 100%;
      float: left;
      text-align: center;
      color: #c7ba89;
      font-family: "Merriweather";
      font-style: italic;
      letter-spacing: -1.2px;
      padding: 0;
      font-size: 48px;
      font-weight: 500;
      line-height: 62px;
      margin: 0 0 10px 0;
    }
    .content {
      width: 100%;
      float: left;
      position: relative;
      padding: 0;
      .faq-block {
        width: 100%;
        float: left;
        position: relative;
        .el-collapse-item {
          .el-collapse-item__header {
            display: -webkit-box;
            display: -ms-flexbox;
            display: flex;
            -webkit-box-align: center;
            -ms-flex-align: center;
            align-items: center;
            line-height: 20px;
            color: #303133;
            cursor: pointer;
            background: transparent;
            border-bottom: 1px solid #a7bdd2;
            font-size: 16px;
            font-weight: 600;
            -webkit-transition: border-bottom-color 0.3s;
            transition: border-bottom-color 0.3s;
            outline: 0;
            padding: 16px 0;
            i {
              &::before {
                background: #c7ba89;
                border-radius: 100px;
                width: 24px;
                height: 24px;
                display: block;
                line-height: 24px;
                position: relative;
                text-align: center;
              }
            }
          }
          .el-collapse-item__wrap {
            background-color: transparent;
          }
        }
      }
    }
  }
  .contact {
    //width: 100%;
    float: left;
    border-radius: 24px;
    padding: 32px;
    background-color: #fff;
    position: relative;
    margin-top: 88px;
    span {
      width: 100%;
      float: left;
      text-align: center;
    }
    span.head {
      color: #1d3b69;
      font-weight: 600;
      font-size: 22px;
      line-height: 41px;
      margin: 0 0 8px 0;
    }
    span.title {
      font-size: 16px;
      line-height: 24px;
      font-weight: 400;
      color: #1d3b69d9;
      margin: 0 0 32px 0;
    }
    .cta {
      width: 100%;
      display: -webkit-flex;
      display: -moz-flex;
      display: -ms-flex;
      display: -o-flex;
      display: flex;
      justify-content: center;
      align-items: center;
      a {
        height: 51px;
        line-height: 51px;
        float: left;
        padding: 0 40px;
        background-color: #204289;
        color: #fff;
        font-weight: 600;
        font-size: 18px;
        border-radius: 7px;
        transition: 0.3s all;
        text-decoration: none;
        &:hover {
          background-color: #c7ba89;
          color: #204289;
        }
      }
    }
  }
}
@media only screen and (min-width: 768px) {
  .row {
    .order-1 {
      order: 2 !important;
    }
    .order-2 {
      order: 1 !important;
      margin: 72px 0 0 0;
    }
    .order-3 {
      order: 3 !important;
      margin: 72px 0 0 0;
    }
  }
  .post-cards.horizontal .item {
    .data {
      width: calc(100% - 378px);
      margin: 0 24px 0 0;
      .title {
        display: -webkit-box !important;
      }
      .author {
        display: block !important;
      }
    }
    .thumbnail {
      width: 354px;
      height: 182px;
      order: 0 !important;
    }
  }
  .how-we-review {
    .wrapper {
      .left {
        width: calc(100% - 280px);
        span.description {
          padding-left: 60px;
        }
      }
      .right {
        float: right;
      }
    }
  }
  .faq {
    .content {
      padding: 0 85px;
    }
    .box {
      padding: 60px 123px;
      border-radius: 24px;
    }
  }
}
</style>

import Vue from "vue";
import VueI18n from "vue-i18n";
import { getCookie, setCookie } from "../config/utils";

Vue.use(VueI18n);

export const LANGLIST = {
  enUS: {
    key: "en",
    code: "enUS",
    name: "English",
    currency: "USD",
    local: require("./enUS.json"),
  },

  // zhCN: {
  //   key: "zh",
  //   code: "zhCN",
  //   name: "简体中文",
  //   currency: "CNY",
  //   local: require("./zhCN.json"),
  // },
  ptPT: {
    key: "pt",
    code: "ptPT",
    name: "Português",
    currency: "EUR",
    hide: false,
    local: require("./ptPT.json"),
  },
};

// const DEFALUT_ITEM = Object.values(LANGLIST).find(
//   (item) => !item.hide && navigator.language.includes(item.key)
// );
//const DEFAULT_LANG = DEFALUT_ITEM ? DEFALUT_ITEM.code : "ptPT";
const DEFAULT_LANG = "ptPT";
const LOCALE_KEY = "lang";
getCookie("GID") ||
  setCookie("GID", `G${Math.floor(Math.random() * 1000000000000)}`);

const locales = Object.values(LANGLIST).reduce((curr, prev) => {
  curr[prev.code] = prev.local;
  return curr;
}, {});

const i18n = new VueI18n({
  locale: DEFAULT_LANG,
  messages: locales,
});

export const setup = (lang) => {
  if (lang === undefined) {
    lang = getCookie(LOCALE_KEY);
    if (locales[lang] === undefined) {
      lang = DEFAULT_LANG;
    }
  }
  setCookie(LOCALE_KEY, lang, 7);

  Vue.config.lang = lang;
  i18n.locale = lang;
};
setup();
export default i18n;

<template>
  <div :class="['main-warpper']">
    <slider
      animation="fade"
      :autoplay="true"
      :interval="5000"
      :touch="true"
      style="aspect-ratio: 85/28; z-index: 1"
      width="auto"
      height="auto"
      v-if="advertiesSort['3']"
      v-show="$route.params.type == `home`"
    >
      <slider-item v-for="(item, index) in advertiesSort['3']" :key="index">
        <a :href="item.LinkUrl" target="_blank"
          ><img :src="item.ImageUrl" alt="" width="100%" height="100%"
        /></a>
      </slider-item>
    </slider>

    <div
      class="main-ad-banner"
      v-for="item of advertiesSort['4']"
      :key="item.Id"
      v-show="item.CategoryId == $route.params.type || item.CategoryId == -999"
    >
      <a :href="item.LinkUrl" target="_blank">
        <img
          :src="item.ImageUrl"
          alt=""
          style="object-fit: cover; width: 100%; height: 100%"
        />
      </a>
    </div>
    <!-- <div
      class="main-ad-banner hidden-md-and-up"
      v-for="item of advertiesSort['6']"
      :key="item.Id"
    >
      <a :href="item.LinkUrl" target="_blank">
        <img
          :src="item.ImageUrl"
          alt=""
          style="object-fit: cover; width: 100%; height: 100%"
        />
      </a>
    </div> -->

    <div class="panel" v-if="$route.params.type != 'home'">
      <div class="panel-header">
        <div class="title">{{ config.Title }}</div>
      </div>

      <div class="panel-body">
        <div class="skeletion" v-if="!articleList.length && loading">
          <SKeletion class="news-item" v-for="i of 5" :key="i" />
        </div>
        <div class="news-item" v-for="item of articleList" :key="item.Id">
          <div class="news-img">
            <img :src="item.ImgUrl" alt="" />
          </div>
          <div class="news-info">
            <div class="title" @click="newWindows(item.Id)">
              {{ item.Title }}
            </div>
            <div class="content">
              {{ item.SeoDescription }}
            </div>

            <div class="more-info row-start">
              <div class="date">
                {{ item.UpdateTime | formatDate }}
              </div>
              <!-- <div class="times" style="color: red; padding-right: 2px">
                {{ item.Click }}
                <span style="color: #999">{{ $t("app_0023") }}</span>
              </div> -->
            </div>
          </div>
        </div>
      </div>
      <div class="row-center loadmore">
        <el-button @click="loadmore" :loading="loading" class="btn-loadmore">{{
          loading ? $t("app_0024") + "..." : $t("app_0025")
        }}</el-button>
      </div>
    </div>

    <template v-else>
      <div
        class="panel"
        v-for="category of navbar"
        :key="`panel_${category.Id}`"
      >
        <div class="panel-header row-between">
          <div class="title">{{ category.Title }}</div>
          <div class="more">
            <a
              href="javascript:;"
              target="_blank"
              @click="categoryNewWindows(category.Id)"
              ><i class="el-icon-plus"></i> {{ $t("app_0027") }}</a
            >
          </div>
        </div>

        <div class="panel-body">
          <div class="skeletion" v-if="!articleList.length && loading">
            <SKeletion class="news-item" v-for="i of 5" :key="i" />
          </div>
          <div
            class="news-item"
            v-for="item of getCategoryNewsList(category)"
            :key="item.Id"
          >
            <div class="news-img">
              <img :src="item.ImgUrl" alt="" />
            </div>
            <div class="news-info">
              <div class="title" @click="newWindows(item.Id)">
                {{ item.Title }}
              </div>
              <div class="content">
                {{ item.SeoDescription }}
              </div>

              <div class="more-info row-start">
                <div class="date">
                  {{ item.UpdateTime | formatDate }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </template>

    <div class="main-ad-banner"></div>
    <div class="main-ad-banner"></div>
  </div>
</template>
<script>
import { getArticleList } from "@/axios/api";
import { mapState, mapGetters } from "vuex";
import { Slider, SliderItem } from "vue-easy-slider";
import SKeletion from "@/components/skeletion/NewsItem.vue";
export default {
  components: {
    SKeletion,
    Slider,
    SliderItem,
  },
  name: "NewsMain",
  props: {},
  data() {
    return {
      config: {},
      params: {
        CategoryId: "",
        IdFrom: 0,
        IdTo: 10,
      },
      articleList: [],
      loading: false,
      isDone: false,
    };
  },
  computed: {
    ...mapState(["navbar", "advertiseList"]),
    ...mapGetters(["advertiesSort"]),
  },
  watch: {
    $route: {
      immediate: true,
      handler(route) {
        this.config = this.navbar.find(
          (item) => item.Id == route.params.type
        ) || { Title: "Home" };
        this.articleList = [];
        this.params.CategoryId =
          route.params.type == "home" ? 0 : route.params.type;
        this.params.IdFrom = 0;
        this.params.IdTo = route.params.type == "home" ? 50 : 10;
        this.getArticleList();
        this.$emit("closeMenu", false);
      },
    },
    navbar(list) {
      if (list) {
        this.config = list.find(
          (item) => item.Id == this.$route.params.type
        ) || { Title: "Home" };
      }
    },
  },
  methods: {
    async getArticleList() {
      this.loading = true;
      const res = await getArticleList(this.params);
      this.articleList.push(...res.data);
      this.loading = false;
      this.isDone = res.data.length < 10;
    },
    loadmore() {
      if (this.loading) return;
      this.params.IdFrom = this.params.IdTo + 1;
      this.params.IdTo += 10;
      this.getArticleList();
    },
    newWindows(id) {
      window.open(window.location.origin + "/news/" + id);
    },
    categoryNewWindows(id) {
      window.open(window.location.origin + "/" + id);
    },
    getCategoryNewsList(category) {
      return (
        this.articleList.filter((item) => item.CategoryId == category.Id) || []
      );
    },
  },
};
</script>
<style lang="scss">
.main-warpper {
  flex: 1;
  // &:not(.is-home) {
  //   .main-ad-banner,
  //   .slider {
  //     display: none;
  //   }
  // }
  .slider {
    margin-bottom: 10px;
  }
  .main-ad-banner {
    //height: 110px;
    margin-bottom: 10px;
    a {
      display: flex;
    }
  }
  .panel {
    background: #fff;
    margin-bottom: 16px;
    .panel-header {
      a {
        text-decoration: none;
        color: #2c3e50;
        &:hover {
          color: #409eff;
          text-decoration: underline;
        }
      }
    }
    .panel-tabs {
      background: #5d8af3;
      padding: 8px 16px 0;
      .item {
        font-size: 16px;
        padding: 6px 12px;
        color: #fff;

        &.active {
          background: #fff;
          color: #232323;
          border-radius: 6px 6px 0 0;
        }
      }
    }

    &-header {
      padding: 16px;
      border-bottom: 1px solid #ddd;
      .title {
        display: flex;
        align-items: center;
        position: relative;
        font-size: 16px;
        font-weight: bold;
        &::before {
          content: "";
          width: 6px;
          height: 6px;
          background: #2c3e50;
          float: left;
          margin-right: 6px;
          transform: rotate(-45deg);
          border-radius: 1px;
          position: relative;
        }
      }
      .el-link {
        font-size: 12px;
      }
    }
    &.panel-details {
      .title:before {
        content: unset;
      }
    }

    &-body {
      .news-item {
        display: flex;
        padding: 16px 0;
        margin: 0 16px;
        text-align: center;

        + .news-item {
          border-top: 1px solid #ddd;
        }

        .news-img {
          width: 26%;
          aspect-ratio: 5 / 3;
          overflow-clip-margin: content-box;
          overflow: clip;
          img {
            width: 220px;
            height: 150px;
            -moz-transition: -moz-transform 0.5s ease-in-out;
            -o-transition: -o-transform 0.5s ease-in-out;
            -webkit-transition: -webkit-transform 0.5s ease-in-out;
            transition: transform 0.5s ease-in-out;
          }
          img:hover {
            transform: scale(1.2);
          }
        }

        .news-info {
          width: 0;
          flex: 1;
          display: flex;
          flex-direction: column;
          margin: 0 16px;
          text-align: justify;
          justify-content: space-between;
          word-break: break-all;
          .title {
            font-size: 16px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            font-weight: bold;
          }
          .title:hover {
            cursor: pointer;
            color: #fc7e02;
          }

          .content {
            margin: 10px 0;
            font-size: 14px;
            color: #666;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            overflow: hidden;
            text-overflow: ellipsis;
            word-wrap: break-word;
            -webkit-line-clamp: 3;
            -webkit-box-orient: vertical;
          }

          .more-info {
            font-size: 12px;
            color: #999;
            white-space: nowrap;
            .times {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .loadmore {
      border-top: 1px solid #f3f3f3;
      .btn-loadmore {
        width: 100%;
        border: 0;
        padding: 24px 0;
      }
    }
  }
}
</style>

<template>
  <el-skeleton :loading="loading" animated>
    <div slot="template" class="item">
      <el-skeleton-item variant="image" class="img" />
      <div class="info">
        <el-skeleton-item variant="h1" style="width: 50%" />
        <div class="content">
          <el-skeleton-item variant="text" />
          <el-skeleton-item variant="text" />
          <el-skeleton-item variant="text" style="width: 30%" />
        </div>
        <div class="more-info">
          <el-skeleton-item variant="text" style="width: 20%" />
          <el-skeleton-item
            variant="text"
            style="width: 20%; margin-left: 16px"
          />
        </div>
      </div>
    </div>
  </el-skeleton>
</template>
<script>
export default {
  data() {
    return {
      loading: true,
    };
  },
};
</script>
<style lang="scss">
.item {
  display: flex;
  align-items: flex-start;
}
.img {
  width: 26% !important;
  height: 100% !important;
  aspect-ratio: 5 / 3;
}

.info {
  flex: 1;
  display: flex;
  flex-direction: column;
  margin: 0 16px;
  text-align: justify;
  .content {
    margin: 12px 0;
  }
  .more-info {
    display: flex;
    align-items: center;
  }
}
</style>

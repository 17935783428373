/**
 *
 * @param {cookie name} cname
 * @param {cookie value} cvalue
 * @param {cookie expires} exdays
 */
export const setCookie = (cname, cvalue, exdays, path = "/") => {
  if (!cname) return;
  let expires;
  if (typeof exdays == "number") {
    let d = new Date();
    d.setTime(d.getTime() + exdays * 24 * 60 * 60 * 1000);
    expires = "expires=" + d.toGMTString() + ";";
  }
  if (typeof exdays == "object") {
    expires = "expires=" + exdays.toGMTString() + ";";
  }
  const cookieStr = cname + "=" + cvalue + "; " + expires + "path=" + path;
  document.cookie = cookieStr;
};

/**
 *
 * @param {cookie name} cname
 */
export const getCookie = (cname) => {
  let name = cname + "=";
  let ca = document.cookie.split(";");
  for (let i = 0; i < ca.length; i++) {
    let c = ca[i].trim();
    if (c.indexOf(name) == 0) {
      return c.substring(name.length, c.length);
    }
  }
  return "";
};

<template>
  <div class="main-warpper">
    <div
      class="home-lg-ad-banner flex-center hidden-md-and-up"
      v-for="item of advertiesSort['6']"
      :key="item.Id"
      style="margin-top: 1px !important"
      v-show="item.CategoryId == details.CategoryId || item.CategoryId == -999"
    >
      <a :href="item.LinkUrl" target="_blank">
        <img
          :src="item.ImageUrl"
          alt=""
          style="object-fit: cover; width: 100%; height: 100%"
        />
      </a>
    </div>
    <div
      class="home-lg-ad-banner flex-center hidden-sm-and-down"
      v-for="item of advertiesSort['2']"
      :key="item.Id"
      v-show="item.CategoryId == details.CategoryId || item.CategoryId == -999"
    >
      <a :href="item.LinkUrl" target="_blank">
        <img
          :src="item.ImageUrl"
          alt=""
          style="object-fit: cover; width: 100%; height: 100%"
        />
      </a>
    </div>
    <el-skeleton
      class="details-skeleton"
      v-if="loading"
      animated
      :rows="10"
      loading
    ></el-skeleton>
    <div v-else class="panel panel-details">
      <div class="panel-header">
        <div class="title">{{ details.Title }}</div>
        <div class="more-info">
          <div class="info-left">
            <div class="item">
              <div class="label hidden-sm-and-down">{{ $t("app_0032") }}：</div>
              <div class="value">{{ details.UpdateTime | formatDate }}</div>
            </div>
            <div class="item hidden-sm-and-down">
              <div class="label">{{ $t("app_0033") }}：</div>
              <div class="value">{{ locahost }}</div>
            </div>
          </div>

          <!-- <div class="info-right">
            <div class="item hidden-sm-and-down">
              <div class="label">{{ $t("app_0034") }}：</div>
              <div class="value" style="color: red">
                {{ details.CommentCount }}
              </div>
            </div>
            <div class="item">
              <div class="label">{{ $t("app_0035") }}：</div>
              <div class="value" style="color: red">{{ details.Click }}</div>
            </div>
          </div> -->
        </div>
      </div>

      <div class="panel-body">
        <div class="zhaiyao">
          <p>{{ details.Zhaiyao }}</p>
        </div>
        <!-- <div class="main-ad-banner"><img alt="广告" />828*120</div> -->
        <div class="content-text" v-html="details.ContentHTML"></div>
        <div class="row-start label-text">
          {{ $t("app_0036") }}：
          <p v-for="(keyWord, index) of keyWordList" :key="index">
            {{ keyWord }}
          </p>
        </div>

        <el-row class="article">
          <el-col :span="12" class="article-content">
            <div class="article-controller previous">
              <span class="head">{{ $t("app_0038") }}</span>
              <div class="article-item">
                <div class="thumbnail hidden-md-and-up">
                  <a
                    href="javascript:;"
                    @click="detailsNewWidow(details.IdPre)"
                  >
                    <img
                      :src="details.ImgUrlPre"
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </a>
                </div>
                <div class="data">
                  <div class="info">
                    <span class="category">
                      <a
                        href="javascript:;"
                        @click="categoryNewWidow(details.CategoryIdPre)"
                        >{{ details.CategoryNamePre }}</a
                      >
                    </span>
                    <span class="date">{{
                      details.UpdateTimePre | detailsFormatDate
                    }}</span>
                  </div>
                  <div class="heading">
                    <a
                      href="javascript:;"
                      @click="detailsNewWidow(details.IdPre)"
                      >{{ details.TitlePre }}</a
                    >
                  </div>
                </div>
                <div class="thumbnail hidden-sm-and-down">
                  <a
                    href="javascript:;"
                    @click="detailsNewWidow(details.IdPre)"
                  >
                    <img
                      :src="details.ImgUrlPre"
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </a>
                </div>
              </div>
            </div>
          </el-col>
          <el-col :span="12" class="article-content">
            <div class="article-controller next">
              <span class="head">{{ $t("app_0037") }}</span>
              <div class="article-item">
                <div class="thumbnail">
                  <a
                    href="javascript:;"
                    @click="detailsNewWidow(details.IdNext)"
                  >
                    <img
                      :src="details.ImgUrlNext"
                      alt=""
                      width="100%"
                      height="100%"
                    />
                  </a>
                </div>
                <div class="data">
                  <div class="info">
                    <span class="category">
                      <a
                        href="javascript:;"
                        @click="categoryNewWidow(details.CategoryIdNext)"
                        >{{ details.CategoryNameNext }}</a
                      >
                    </span>
                    <span class="date">{{
                      details.UpdateTimeNext | detailsFormatDate
                    }}</span>
                  </div>
                  <div class="heading">
                    <a
                      href="javascript:;"
                      @click="detailsNewWidow(details.IdNext)"
                      >{{ details.TitleNext }}</a
                    >
                  </div>
                </div>
              </div>
            </div>
          </el-col>
        </el-row>

        <div class="comment-template">
          <div class="comment-content">
            <h2 v-show="articleCommentsList.length">{{ $t("app_0043") }}</h2>
            <ul>
              <li v-for="(item, index) of articleCommentsList" :key="index">
                <div class="comment-author">
                  <el-avatar shape="square" :src="squareUrl"></el-avatar>
                  <div class="info">
                    <span class="name">{{ item.NickName }}</span>
                    <span class="date">{{
                      item.AddTime | detailsFormatDate
                    }}</span>
                  </div>
                </div>
                <em
                  class="comment-awaiting-moderation"
                  v-show="item.Status == 0"
                  ><font style="vertical-align: inherit"
                    ><font style="vertical-align: inherit">{{
                      $t("app_0223")
                    }}</font></font
                  ></em
                >
                <div class="comment-text">
                  <p>{{ item.Content }}</p>
                </div>
              </li>
              <li v-show="!articleCommentsList.length">
                <h2>{{ $t("app_0044") }}</h2>
              </li>
            </ul>
            <div class="comment-respond">
              <h3 class="comment-reply-title">{{ $t("app_0045") }}</h3>
              <el-form
                :rules="rules"
                class="comment-form"
                :model="ruleForm"
                ref="ruleForm"
              >
                <el-form-item class="comment-notes">
                  <span class="email-notes">{{ $t("app_0046") }}.</span>
                  <span class="required-field-message"
                    >{{ $t("app_0047") }} <span class="required">*</span></span
                  >
                </el-form-item>
                <el-form-item class="comment-form-content" prop="comment">
                  <label for=""
                    >{{ $t("app_0043") }}<span class="required">*</span></label
                  >
                  <el-input
                    type="textarea"
                    name="comment"
                    id="comment"
                    style="font-family: inter, sans-serif; font-size: 16px"
                    v-model="ruleForm.comment"
                  ></el-input>
                </el-form-item>
                <el-form-item class="comment-form-author" prop="name">
                  <label for=""
                    >{{ $t("app_0048") }}<span class="required">*</span></label
                  >
                  <el-input
                    id="name"
                    name="name"
                    type="text"
                    v-model="ruleForm.name"
                  ></el-input>
                </el-form-item>
                <el-form-item class="comment-form-email" prop="email">
                  <label for=""
                    >{{ $t("app_0049") }}<span class="required">*</span></label
                  >
                  <el-input
                    id="email"
                    name="email"
                    type="text"
                    v-model="ruleForm.email"
                  ></el-input>
                </el-form-item>
                <el-form-item class="form-submit">
                  <el-button
                    name="submit"
                    id="submit"
                    class="submit"
                    @click="submitForm('ruleForm')"
                    >{{ $t("app_0050") }}
                  </el-button>
                </el-form-item>
              </el-form>
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- <div class="panel">
      <div class="panel-header">
        <div class="title">{{ $t("app_0039") }}</div>
      </div>

      <div class="panel-body">
        <div
          class="news-item"
          v-for="item of 5"
          :key="item"
          @click="$router.push(`/news/${item}`)"
        >
          <div class="news-img"></div>
          <div class="news-info">
            <div class="title">
              小口利成为首行谐足美国印太司令部的非佯兵瓜犹
            </div>
            <div class="content">
              小口利成为首行谐足美国印太司令部的非佯兵瓜犹小口利成为首行谐足美国印太司令部的非佯兵瓜犹小口利成为首行谐足美国印太司令部的非佯兵瓜犹小口利成为首行谐足美国印太司令部的非佯兵瓜犹
            </div>

            <div class="more-info row-start">
              <div class="date">2023-12-01 23:23:23</div>
              <div class="times">12123浏览</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { getArticleDetails } from "@/axios/api";
import { getArticleCommentsList } from "@/axios/api";
import { postComments } from "@/axios/api";
import { mapGetters } from "vuex";

export default {
  name: "NewsMain",
  props: {
    id: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      params: {
        ArticleID: 0,
        NickName: "",
        Email: "",
        Content: "",
      },
      details: {},
      articleCommentsList: {},
      keyWordList: [],
      locahost: window.location.host,
      loading: false,
      squareUrl:
        "https://cube.elemecdn.com/9/c2/f0ee8a3c7c9638a54940382568c9dpng.png",
      ruleForm: {
        comment: "",
        name: "",
        email: "",
      },
      rules: {
        comment: [
          { required: true, message: this.$t("app_0060"), trigger: "blur" },
        ],
        name: [
          { required: true, message: this.$t("app_0061"), trigger: "blur" },
        ],
        email: [
          { required: true, message: this.$t("app_0062"), trigger: "blur" },
        ],
      },
    };
  },
  computed: {
    ...mapGetters(["advertiesSort"]),
  },
  mounted() {
    this.loading = true;
    this.getArticleDetails();
    this.getArticleCommentsList();
  },
  methods: {
    async getArticleDetails() {
      const res = await getArticleDetails(this.id);
      this.details = res.data;
      this.keyWordList = this.details.SeoKeyword.split(",");
      this.loading = false;
      this.$root.$emit("changeNav", this.details.CategoryId);
    },
    async getArticleCommentsList() {
      const res = await getArticleCommentsList(this.id);
      this.articleCommentsList = res.data;
    },
    async postComments() {
      const res = await postComments(this.params);
      if (res) {
        this.getArticleCommentsList();
      } else {
        this.$message({
          message: this.$t("app_0063"),
          type: "warning",
        });
      }
    },
    detailsNewWidow(Id) {
      if (Id) {
        window.open(window.location.origin + "/news/" + Id);
      }
    },
    categoryNewWidow(categoryId) {
      this.$router.push("/" + categoryId);
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          const formData = this.$refs[formName].model;
          this.params.ArticleID = this.id;
          this.params.NickName = formData.name;
          this.params.Email = formData.email;
          this.params.Content = formData.comment;
          this.postComments();
          this.$refs[formName].resetFields();
        } else {
          console.log("error submit!!");
          return false;
        }
      });
    },
  },
};
</script>
<style lang="scss">
.main-warpper {
  width: 0;
  .home-lg-ad-banner {
    width: 100%;
    height: auto !important;
    a {
      display: flex;
    }
  }
  .details-skeleton {
    background: #fff;
    padding: 10px;
  }
  .panel-details {
    .panel-header {
      border-bottom: none;
    }
    .title {
      font-size: 24px;
      font-weight: bold;
    }

    .more-info {
      display: flex;
      align-items: center;
      justify-content: space-between;
      font-size: 12px;
      margin-top: 16px;
      height: 20px;
      line-height: 20px;
      .info-left,
      .info-right {
        display: flex;
        align-items: center;
        justify-content: space-between;
      }
      .item {
        display: flex;
        align-items: center;

        .label {
          opacity: 0.7;
        }
        .value {
          opacity: 0.7;
        }

        + .item {
          margin-left: 16px;
        }
      }
    }

    .panel-body {
      padding: 16px;
      .zhaiyao {
        color: #777;
        border-bottom: 2px solid #e1e1e1;
        border-top: 2px solid #e1e1e1;
        padding: 10px;
        background: #f0f0f0;
        margin: 10px 0;
        p {
          font-size: 12px;
        }
      }
      .main-ad-banner {
        width: 100%;
        height: 120px;
      }
      .content-text {
        min-height: 100px;
        img {
          max-width: 100%;
        }
      }
      .label-text {
        margin: 20px 0;
        font-weight: bold;
        p {
          color: #fc7e02;
          margin-right: 10px;
        }
      }
      .next-article {
        font-weight: bold;
        line-height: 20px;
        margin: 10px 0px;
        div {
          display: flex;
          align-items: center;
          justify-content: flex-start;
        }
        div:hover {
          cursor: pointer;
        }
        p {
          color: #fc7e02;
        }
      }
      .article {
        margin-right: -15px;
        margin-left: -15px;
        display: -webkit-box;
        display: -webkit-flex;
        display: -ms-flexbox;
        display: flex;
        -webkit-flex-wrap: wrap;
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
        .article-content {
          // -webkit-box-flex: 0;
          // -webkit-flex: 0 0 50%;
          // -ms-flex: 0 0 50%;
          // flex: 0 0 50%;
          // max-width: 45%;
          padding: 0px 15px 0px 15px;
          a {
            touch-action: manipulation;
            text-decoration: none;
            color: inherit;
            background-color: transparent;
          }
          .article-controller {
            margin: 40px 0 0 0;
            width: 100%;
            float: left;
            position: relative;
            .head {
              font-size: 18px;
              font-weight: 500;
              color: #334250;
              text-transform: uppercase;
              letter-spacing: 0.8px;
            }
            .article-item {
              width: 100%;
              float: left;
              padding: 16px 0;
              margin: 10px 0 0 0;
              border-bottom: 1px solid #d0deea;
              border-top: 1px solid #d0deea;
              .thumbnail {
                width: 100px;
                height: 75px;
                float: left;
                position: relative;
                overflow: hidden;
                margin-right: 15px;
                a {
                  width: 100%;
                  height: 100%;
                  display: inline-block;
                  float: left;
                  img {
                    width: 100%;
                    height: 100%;
                    display: inline-block;
                    float: left;
                    background-size: 100%;
                    background-repeat: no-repeat;
                    object-fit: cover;
                    transition: 0.6s all;
                    &:hover {
                      transform: scale(1.1);
                    }
                  }
                }
              }
              .data {
                width: calc(100% - 115px);
                float: left;
                position: relative;
                .info {
                  display: block;
                  width: 100%;
                  float: left;
                  margin: 0 0 9px 0;
                  padding-top: 2px;
                  .category {
                    margin-right: 16px;
                    font-weight: 600;
                    font-size: 13px;
                    line-height: 15px;
                    text-transform: uppercase;
                    color: #204289;
                    position: relative;
                    &::before {
                      content: "";
                      width: 6px;
                      height: 6px;
                      background: #2c3e50;
                      float: left;
                      margin: 4px 6px 0px 0px;
                      transform: rotate(-45deg);
                      border-radius: 1px;
                      position: relative;
                    }
                  }
                  .date {
                    color: #8e8f9d;
                    font-weight: 400;
                    font-size: 13px;
                    line-height: 15px;
                    text-transform: uppercase;
                  }
                  span {
                    float: left;
                  }
                }
                .heading {
                  width: 100%;
                  float: left;
                  font-size: 14px;
                  font-weight: 600;
                  color: #334250;
                  line-height: 1.2;
                  display: -webkit-box;
                  -webkit-line-clamp: 2;
                  -webkit-box-orient: vertical;
                  overflow: hidden;
                }
              }
            }
          }
          .next {
            .head {
              float: left;
            }
          }
          .previous {
            display: grid;
            visibility: visible;
            .head {
              float: right;
              text-align: right;
            }
            .article-item {
              .thumbnail {
                margin-right: 0;
                float: right;
              }
            }
          }
        }
      }
      .comment-template {
        width: 100%;
        position: relative;
        margin-top: 0px;
        margin-bottom: 30px;
        padding-bottom: 40px;
        .comment-content {
          width: 100%;
          position: relative;
          ul {
            list-style: none;
            padding: 0;
            margin: 0;
            width: 100%;
            li {
              width: 100%;
              margin: 10px 0;
              padding: 10px 0;
              border-style: dotted;
              border-color: #000;
              border-width: 0 0 1px 0;
              .comment-author {
                width: 100%;
                .el-avatar {
                  width: 40px;
                  height: 40px;
                  margin-right: 15px;
                  float: left;
                }
                .info {
                  width: calc(100% - 100px);
                  position: relative;
                  .name {
                    float: left;
                    width: 100%;
                    font-style: normal;
                    font-size: 15px;
                    margin-bottom: 5px;
                  }
                  .date {
                    width: 100%;
                    display: inline-block;
                    font-size: 12px;
                    color: #9ca5aa;
                    line-height: 12px;
                    text-transform: uppercase;
                    font-family: "Roboto";
                    letter-spacing: -0.215px;
                  }
                }
              }
              .comment-awaiting-moderation {
                width: calc(100% - 66px);
                float: left;
                margin: 15px 0 0 55px;
                font-size: 13px;
                line-height: 20px;
                letter-spacing: -0.1px;
                color: #a20e0e;
                font-weight: 400;
                background: rgba(207, 19, 19, 0.1019607843);
                padding: 7px 0px 4px 11px;
                border-radius: 2px;
                font-style: italic;
              }
              .comment-text {
                width: calc(100% - 100px);
                margin-left: 55px;
                margin-top: 7px;
                margin-bottom: 9px;
                font-size: 14px;
                line-height: 31px;
                letter-spacing: 0;
                color: #091e2c;
                font-weight: 400;
              }
            }
          }
          .comment-respond {
            width: 100%;
            position: relative;
            margin-top: 20px;
            h3 {
              width: 100%;
              float: left;
              padding: 0;
              margin: 0 0 10px 0;
            }
            .comment-notes {
              font-size: 16px;
              color: #8e8f9d;
              line-height: 24px;
              span {
                font-size: 16px;
                color: #8e8f9d;
                line-height: 24px;
              }
            }
            .comment-form-content {
              textarea {
                width: 100%;
                float: left;
                min-height: 130px;
                height: 130px;
                padding: 20px 20px;
                border-radius: 6px;
                margin: 15px 0 15px 0;
                resize: none;
                border: 1px solid #eff0f2;
                outline: none;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              }
            }
            .comment-form-author {
              width: calc(50% - 10px);
              float: left;
              label {
                width: 100%;
                float: left;
                padding: 0;
                margin: 0 0 5px 0;
                font-size: 15px;
                color: #334250;
                font-weight: 500;
              }
              input {
                width: 100%;
                height: 48px;
                padding: 0px 10px 0px 10px;
                outline: none;
                border: 1px solid #eff0f2;
                font-size: 14px;
                border-radius: 6px;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              }
            }
            .comment-form-email {
              width: calc(50% - 10px);
              float: right;
              label {
                width: 100%;
                float: left;
                padding: 0;
                margin: 0 0 5px 0;
                font-size: 15px;
                color: #334250;
                font-weight: 500;
              }
              input {
                width: 100%;
                height: 48px;
                padding: 0px 10px 0px 10px;
                outline: none;
                border: 1px solid #eff0f2;
                font-size: 14px;
                border-radius: 6px;
                box-shadow: 0px 1px 2px rgba(16, 24, 40, 0.05);
              }
            }
            .form-submit {
              display: inline-block;
              width: 100%;
              padding: 0;
              margin: 0 0 0 0;
              .submit {
                padding: 0 30px;
                margin: 10px 0 0 0;
                outline: none;
                height: 51px;
                line-height: 51px;
                color: #fff;
                background-color: #204289;
                border: none;
                font-size: 18px;
                font-weight: 600;
                border-radius: 7px;
                cursor: pointer;
                transition: 0.3s all;
                width: 100%;
                &:hover {
                  background-color: #c7ba89;
                  color: #204289;
                }
              }
            }
          }
        }
      }
    }
  }
}
</style>

<template>
  <div class="home">
    <div class="page-header">
      <div class="logo-warpper row-between hidden-md-and-up">
        <div>
          <img
            src="../assets/ico_1.png"
            alt=""
            style="width: 28px"
            @click="showSlide = true"
          />
        </div>
        <div>
          <img src="../assets/logo.png" alt="logo" style="width: 136px" />
        </div>
        <div>
          <div class="navbar row-center">
            <el-dropdown @command="changeLang" class="nav-item">
              <span class="el-dropdown-link">
                <img src="../assets/ico_3.png" alt="" style="width: 28px" />
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item
                  v-for="(item, index) of langList"
                  :key="index"
                  :command="item.code"
                  >{{ item.name }}</el-dropdown-item
                >
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </div>
      </div>
    </div>
    <div
      :class="['row-between', 'hidden-sm-and-down', this.$route.name]"
      style="padding: 0"
    >
      <a :href="locaOrigin"
        ><img class="logo" src="../assets/logo.png" alt=""
      /></a>
      <div class="home-ad-banner flex-center" v-if="advertiesSort['1']">
        <slider
          animation="fade"
          :autoplay="true"
          :interval="10000"
          :control-btn="false"
          style="aspect-ratio: 20/3"
          width="auto"
          height="120px"
        >
          <slider-item v-for="(item, index) in advertiesSort['1']" :key="index">
            <a :href="item.LinkUrl" target="_blank">
              <img
                :src="item.ImageUrl"
                alt=""
                style="object-fit: cover; width: 100%; height: 100%"
              />
            </a>
          </slider-item>
        </slider>
      </div>
    </div>
    <el-menu
      :default-active="defaultActive"
      class="el-menu-navbar hidden-sm-and-down"
      mode="horizontal"
      text-color="#fff"
      active-text-color="#ddd"
      background-color="transparent"
      router
      v-show="!$route.meta.hideSilderBar"
    >
      <el-menu-item class="el-menu-list" :index="`home`" :route="`/home`">{{
        $t("app_0224")
      }}</el-menu-item>
      <template v-for="item of navbar">
        <el-submenu
          v-if="item.tabs && item.tabs.length"
          :index="item.Id.toString()"
          :key="item.Id"
        >
          <template slot="title">{{ item.Title }}</template>
          <el-menu-item
            class="el-menu-list"
            v-for="sub of item.tabs"
            :key="sub.Id"
            :index="`${item.Id}-${sub.Id}`"
            :route="`/${sub.Id}`"
            >{{ sub.Title }}</el-menu-item
          >
        </el-submenu>
        <el-menu-item
          v-else
          :index="item.Id.toString()"
          :key="item.Id"
          :route="`/${item.Id}`"
          >{{ item.Title }}</el-menu-item
        >
      </template>
    </el-menu>
    <div
      class="home-lg-ad-banner flex-center hidden-sm-and-down"
      v-for="item of advertiesSort['2']"
      :key="item.Id"
      v-show="!$route.meta.hideSilderBar && !$route.meta.hideBanner"
    >
      <a :href="item.LinkUrl" target="_blank">
        <img
          :src="item.ImageUrl"
          alt=""
          style="object-fit: cover; width: 100%; height: 100%"
        />
      </a>
    </div>

    <div class="main row-start">
      <router-view @closeMenu="showSlide = false" />
      <Sidebar class="hidden-sm-and-down" v-show="!$route.meta.hideSilderBar" />
    </div>
    <SlideMenu :show="showSlide" @close="showSlide = false" />
  </div>
</template>

<script>
import Sidebar from "@/components/SideBar.vue";
import SlideMenu from "@/components/SlideMenu.vue";
import { mapState, mapGetters } from "vuex";
import { Slider, SliderItem } from "vue-easy-slider";
import { setup } from "@/locales";
import { LANGLIST } from "@/locales/index";
export default {
  name: "HomeView",
  components: {
    Sidebar,
    SlideMenu,
    Slider,
    SliderItem,
  },
  data() {
    return {
      isCollapse: true,
      showSlide: false,
      langList: LANGLIST,
      locaOrigin: window.location.origin,
      defaultActive: "home",
    };
  },
  computed: {
    ...mapState(["navbar", "advertiseList", "lang"]),
    ...mapGetters(["advertiesSort"]),
  },
  created() {
    this.$store.dispatch("getAdvertiseList");
    this.defaultActive = this.$route.params.type;
    this.$root.$on("changeNav", (CategoryId) => {
      this.defaultActive = CategoryId;
    });
    // this.$root.$on("closeMenu", () => {
    //   this.showSlide = false;
    // });
  },
  methods: {
    changeLang(command) {
      this.$store.commit("SET_LANGUAGE", command);
      setup(command);
      window.location.reload();
    },
  },
};
</script>
<style lang="scss">
.home {
  max-width: 1200px;
  width: 100%;
  flex: 1;
  .page-header {
    width: 100%;
    position: fixed;
    z-index: 999;
    .logo-warpper {
      background: #ffffff;
      border-bottom: 1px solid #103ea5;
    }
  }
  .logo-warpper {
    height: 60px;
    padding: 0 16px;
    .icon-menu {
      width: 30px;
      height: 30px;
    }

    .home-ad-banner {
      flex: 1;
      //background: #ddd;
      height: 120px;
      a {
        display: flex;
      }
    }
  }
  .home-lg-ad-banner {
    width: 100%;
    height: 90px;
    margin-top: 10px;
    //background: #ddd;
    a {
      display: flex;
    }
  }

  .main {
    align-items: flex-start;
    margin-top: 10px;
  }
  .el-menu-navbar {
    margin: 10px 0px 10px 0;
    background: linear-gradient(to right, #2b72ff, #8a46e6);
    user-select: none;
    .el-menu-item {
      padding: 0 16px;
    }
    .el-menu-item,
    .el-submenu__title {
      border-bottom: 0 !important;
    }
    .el-menu-item:hover,
    .el-submenu__title:hover {
      background: #4f5ed3 !important;
    }
    .el-menu-item.is-active,
    .el-submenu.is-active .el-submenu__title {
      color: #fff !important;
      background-color: #4f5ed3 !important;
    }
  }
  .el-menu-vertical-demo:not(.el-menu--collapse) {
    width: 200px;
    min-height: 400px;
  }
  .BettingSites {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
.el-menu--popup {
  background-color: #4f5ed3 !important;
  .el-menu-item:hover,
  .el-submenu__title:hover {
    background: #6396fa !important;
  }
}
</style>

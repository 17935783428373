<template>
  <div id="app">
    <div class="header-container row-between hidden-sm-and-down">
      <div class="login-warpper links" style="visibility: hidden">
        <el-link>{{ $t("app_0001") }}</el-link>
        <el-link>{{ $t("app_0002") }}</el-link>
      </div>

      <div class="navbar row-center">
        <el-dropdown @command="changeLang" class="nav-item">
          <span class="el-dropdown-link">
            <img
              src="../src/assets/ico_3.png"
              alt=""
              style="width: 16px; margin-right: 6px"
            />
            {{ langList[lang].name
            }}<i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-dropdown-menu slot="dropdown">
            <el-dropdown-item
              v-for="(item, index) of langList"
              :key="index"
              :command="item.code"
              >{{ item.name }}</el-dropdown-item
            >
          </el-dropdown-menu>
        </el-dropdown>
        <!-- <el-button class="nav-item" type="text" icon="el-icon-chat-round">{{
          $t("app_0005")
        }}</el-button> -->
        <span class="icon-ptPT"></span>
        <el-button class="nav-item" type="text" @click="openBettingSites">
          {{ $t("app_0064") }}</el-button
        >
      </div>
    </div>
    <router-view />

    <AppFooter />
  </div>
</template>
<script>
import { setup } from "./locales";
import { mapState } from "vuex";
import { LANGLIST } from "@/locales/index";
import AppFooter from "@/components/footer";

export default {
  name: "App",
  components: {
    AppFooter,
  },
  data: function () {
    return {
      langList: LANGLIST,
    };
  },
  computed: {
    ...mapState(["lang"]),
  },
  mounted() {
    this.$store.dispatch("getCategoryList");
  },
  methods: {
    changeLang(command) {
      this.$store.commit("SET_LANGUAGE", command);
      setup(command);
      window.location.reload();
    },
    openBettingSites() {
      window.open(window.location.origin + "/brazil-gambling-sites");
    },
  },
};
</script>
<style lang="scss">
body {
  margin: 0;
  padding: 0;
}
#app {
  font-family: inter, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
  margin: 0 auto;
  font-size: 16px;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  width: 100%;
  align-items: center;
}

.header-container {
  max-width: 1200px;
  width: 100%;
  margin: 0 auto;
  border-bottom: 1px solid #7152ed;
  .navbar {
    .nav-item + .nav-item {
      margin-left: 10px;
    }
  }
  .el-dropdown-link {
    display: flex;
    align-items: center;
  }
  .icon-ptPT {
    background-image: url("/src/assets/lang-map.png");
    background-repeat: no-repeat;
    width: 20px;
    height: 14px;
    display: inline-block;
    background-size: 20px auto;
    background-position-y: -224px;
    margin: 0px 4px 0px 10px;
  }
}

@media only screen and (max-width: 991px) {
  .logo-warpper {
    height: 50px !important;
  }
  .main {
    margin-top: 50px !important;
  }
  .home-lg-ad-banner {
    height: auto !important;
    margin-top: 0 !important;
  }
  .main-warpper {
    .slider,
    .main-ad-banner {
      margin-bottom: 1px !important;
    }
  }
}
@media only screen and (max-width: 520px) {
  .news-item {
    // flex-direction: column-reverse;
    .news-info {
      margin-right: 0 !important;
      .title {
        font-size: 14px !important;
        white-space: normal !important;
      }

      .content {
        display: none !important;
      }
    }
    .news-img {
      width: 96px !important;
      aspect-ratio: 22/15 !important;
    }
    .news-img img {
      width: 100% !important;
      height: 72px !important;
    }
  }
  .article {
    display: grid !important;
    .article-content {
      width: 100% !important;
      .previous {
        .head {
          float: left !important;
          text-align: left !important;
        }
        .thumbnail {
          float: left !important;
          margin-right: 15px !important;
        }
      }
    }
  }
}
</style>

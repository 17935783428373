<template>
  <footer>
    <div class="container">
      <div class="featured footer">
        <span class="text">{{ $t("app_0051") }}:</span>
        <div class="content">
          <div class="logos">
            <div class="logo-swiper">
              <div class="swiper-wrapper">
                <div
                  class="swiper-slide"
                  v-for="(item, index) in brands"
                  :key="index"
                >
                  <div class="item"><i :class="['logo', item.name]"></i></div>
                </div>
              </div>
              <!-- If we need navigation buttons -->
              <div class="swiper-button-prev hidden-md-and-up"></div>
              <div class="swiper-button-next hidden-md-and-up"></div>
              <div class="swiper-pagination hidden-sm-and-down"></div>
            </div>
          </div>
        </div>
      </div>
      <section class="stay-in-touch">
        <div class="row">
          <div class="item-column">
            <span class="head">{{ $t("app_0052") }}</span>
            <span class="description">{{ $t("app_0053") }}.</span>
          </div>
          <div class="item-column">
            <el-input class="input">
              <template #suffix>
                <span class="btn-submit">{{ $t("app_0059") }}</span>
              </template>
            </el-input>
            <div class="trusted-and-responsible">
              <div class="row">
                <i class="icon-password"></i>
                <span class="head">{{ $t("app_0054") }}</span>
                <ul class="logos">
                  <li><i class="logo begambleaware"></i></li>
                  <li><i class="logo gamcare"></i></li>
                  <li><i class="logo age"></i></li>
                  <li><i class="logo dmca"></i></li>
                </ul>
              </div>
              <span class="title">{{ $t("app_0055") }}.</span>
            </div>
          </div>
        </div>
      </section>
      <div class="copyright">
        <div class="row">
          <div class="item-column">
            <span class="text"
              >© 2024 <a href="https://www.gamblingnews.com">Jogos 365</a>.
              {{ $t("app_0056") }}.</span
            >
          </div>
          <div class="item-column">
            <div class="follow-us">
              <span class="head">{{ $t("app_0057") }}:</span>
              <ul>
                <li>
                  <a
                    :href="this.brandSiteInfo.BusinessTelegramAccountLink"
                    target="_blank"
                    ><i class="icon-telegram"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span><span class="path4"></span></i
                  ></a>
                </li>
                <li>
                  <a
                    :href="this.brandSiteInfo.BusinessWhatsappAccountLink"
                    target="_blank"
                    ><i class="icon-whatsapp"
                      ><span class="path1"></span><span class="path2"></span></i
                  ></a>
                </li>
                <li>
                  <a
                    :href="this.brandSiteInfo.BusinessTwitterLink"
                    target="_blank"
                    aria-label="twitter"
                    ><i class="icon-twitter"></i
                  ></a>
                </li>
                <!-- <li>
                  <a href="javascript:;" aria-label="linkedin"
                    ><i class="icon-linkedin"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span><span class="path4"></span></i
                  ></a>
                </li> -->
                <li>
                  <a
                    :href="this.brandSiteInfo.BusinessFacebookLink"
                    target="_blank"
                    aria-label="facebook"
                    ><i class="icon-Facebook"
                      ><span class="path1"></span><span class="path2"></span></i
                  ></a>
                </li>
                <li>
                  <a
                    :href="this.brandSiteInfo.BusinessYoutubeLink"
                    target="_blank"
                    aria-label="youtube"
                    ><i class="icon-youtube"></i
                  ></a>
                </li>
                <li>
                  <a
                    :href="this.brandSiteInfo.BusinessInstagramLink"
                    target="_blank"
                    aria-label="instagram"
                    ><i class="icon-social"></i
                  ></a>
                </li>
                <!-- <li>
                  <a href="https://www.gamblingnews.com/feed/"
                    ><i class="icon-rss"
                      ><span class="path1"></span><span class="path2"></span
                      ><span class="path3"></span><span class="path4"></span></i
                  ></a>
                </li>-->
              </ul>
            </div>
          </div>
        </div>
      </div>
    </div>
  </footer>
</template>

<script>
import { getBasicSiteInfo } from "/src/axios/api";
// core version + navigation, pagination modules:
import Swiper from "swiper";
import { Navigation, Pagination } from "swiper/modules";
// configure Swiper to use modules
Swiper.use([Navigation, Pagination]);
import "swiper/css";
import "swiper/css/pagination";
import "swiper/css/navigation";
export default {
  name: "AppFooter",
  data() {
    return {
      brandSiteInfo: {},
      brands: [
        { link: "", name: "springer" },
        { link: "", name: "inc" },
        { link: "", name: "yahoo" },
        { link: "", name: "sydney" },
        { link: "", name: "politico" },
        { link: "", name: "axios" },
        { link: "", name: "ibt" },
        { link: "", name: "laweekly" },
        { link: "", name: "smith-college" },
        { link: "", name: "unlv" },
        { link: "", name: "wikipedia" },
        { link: "", name: "massachusetts" },
        { link: "", name: "msn" },
        { link: "", name: "telegram" },
        { link: "", name: "bloomberg" },
      ],
      swiper: {},
    };
  },
  mounted() {
    this.getBasicSiteInfo();
    this.swiper = new Swiper(".logo-swiper", {
      slidesPerView: 1,
      spaceBetween: 10,
      breakpoints: {
        990: {
          slidesPerView: 5,
          spaceBetween: 10,
        },
      },
      // configure Swiper to use modules
      modules: [Navigation, Pagination],
      pagination: {
        el: ".swiper-pagination",
        clickable: "true",
        type: "bullets",
      },
      // Navigation arrows
      navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
      },
    });
    this.swiper.updateSize();
  },
  methods: {
    onSwiper(swiper) {
      console.log(swiper);
    },
    onSlideChange() {
      console.log("slide change");
    },
    async getBasicSiteInfo() {
      const res = await getBasicSiteInfo();
      this.brandSiteInfo = res.data;
    },
  },
};
</script>
<style lang="scss">
@media only screen and (min-width: 1200px) {
  footer .featured .content {
    padding: 0 200px;
  }
}
footer {
  max-width: 1200px;
  width: 100%;
  overflow: hidden;
  border-top: 6px solid #204289;
  .row {
    display: flex;
    justify-content: space-between;
  }
  .featured {
    position: relative;
    padding: 32px 0 0 0;

    span.text {
      display: block;
      text-align: center;
      color: #1d3b69;
      font-family: "Merriweather";
      font-style: italic;
      font-size: 14px;
    }

    .logos {
      padding: 0 0 50px 0;
      text-align: center;
      overflow: hidden;
      margin: 0 auto 30px;

      .item {
        display: inline-block;
        margin: 20px 20px 0 20px;
        height: 40px;
        line-height: 40px;
        white-space: nowrap;
        text-align: center;

        i.logo {
          display: inline-block;
          background-size: 100%;
          background-repeat: no-repeat;
          vertical-align: middle;
          text-align: center;
          z-index: 1;

          &.inc {
            width: 70px;
            height: 24px;
            background-image: url("../assets/inc.svg");
          }

          &.springer {
            width: 99px;
            height: 27px;
            background-image: url("../assets/springer.svg?ver=2.0.0");
          }

          &.yahoo {
            width: 76px;
            height: 28px;
            background-image: url("../assets/yahoo.svg?ver=2.0.0");
          }

          &.sydney {
            width: 94px;
            height: 32px;
            background-image: url("../assets/sydney.svg?ver=2.0.0");
          }

          &.politico {
            width: 98px;
            height: 20px;
            background-image: url("../assets/politico.svg?ver=2.0.0");
          }

          &.axios {
            width: 80px;
            height: 20px;
            background-image: url("../assets/axios.svg?ver=2.0.0");
          }

          &.ibt {
            width: 52px;
            height: 20px;
            background-image: url("../assets/ibt.svg?ver=2.0.0");
          }

          &.laweekly {
            width: 79px;
            height: 20px;
            background-image: url("../assets/laweekly.svg?ver=2.0.0");
          }

          &.inews {
            width: 10px;
            height: 28px;
            background-image: url("../assets/inews.svg?ver=2.0.0");
          }

          &.newsbreak {
            width: 149px;
            height: 24px;
            background-image: url("../assets/newsbreak.svg?ver=2.0.0");
          }

          &.bitcoinist {
            width: 130px;
            height: 20px;
            background-image: url("../assets/bitcoinist.svg?ver=2.0.0");
          }

          &.smith-college {
            width: 97px;
            height: 32px;
            background-image: url("../assets/smith-college.svg?ver=2.0.0");
          }

          &.unlv {
            width: 68px;
            height: 20px;
            background-image: url("../assets/unlv.svg?ver=2.0.0");
          }

          &.wikipedia {
            width: 99px;
            height: 28px;
            background-image: url("../assets/wikipedia.svg?ver=2.0.0");
          }

          &.massachusetts {
            width: 81px;
            height: 32px;
            background-image: url("../assets/massachusetts.svg?ver=2.0.0");
          }

          &.msn {
            width: 63px;
            height: 28px;
            background-image: url("../assets/msn.svg?ver=2.0.0");
          }

          &.telegram {
            width: 140px;
            height: 29px;
            background-image: url("../assets/telegram.svg?ver=2.0.0");
          }

          &.bloomberg {
            width: 73px;
            height: 24px;
            background-image: url("../assets/bloomberg-law.svg?ver=2.0.0");
          }
        }
      }
    }
  }

  .copyright {
    padding: 30px 0 0 0;
    margin: 0 0 30px 0;
    position: relative;
    a {
      text-decoration: none;
    }

    span.text {
      display: block;
      font-size: 16px;
      text-align: center;
      color: #1d3b69;
      margin-bottom: 26px;
      line-height: 24px;
    }

    .follow-us {
      display: flex;
      align-items: center;
      span.head {
        font-size: 16px;
        color: #1d3b69;
        line-height: 24px;
        margin-right: 24px;
      }

      ul {
        list-style: none;
        padding: 0;
        margin: 0;
        display: inline-block;
        text-align: center;

        li {
          display: inline-block;
          margin: 0 10px;
          color: #1d3b69;

          i {
            font-size: 24px;
            line-height: 24px;

            &.icon-linkedin {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  section {
    .input {
      display: flex;
      align-items: center;
      input {
        width: 100%;
        height: 62px;
        background-color: #ffffff;
        border-radius: 16px;
        font-size: 18px;
        padding-left: 24px;
        position: relative;
        border: 0;
      }
    }
    .el-input__suffix {
      display: flex;
      align-items: center;
    }

    .btn-submit {
      padding-right: 24px;
      font-size: 18px;
      font-weight: 600;
      color: #204289;
    }
    &.stay-in-touch {
      position: relative;
      margin: 0 0 10px;
      .item-column {
        position: relative;
        display: flex;
        flex-direction: column;
        &:first-child {
          width: 33.3333%;
        }
        &:last-child {
          width: 50%;
        }
        .head {
          color: #1d3b69;
          font-size: 20px;
          line-height: 26px;
          font-weight: 600;
          margin-bottom: 16px;
        }
        .description {
          color: #1d3b69;
          font-size: 18px;
          line-height: 30px;
          margin-bottom: 40px;
        }
      }
      .subscribe {
        .input {
          position: relative;
          input {
            width: 100%;
            height: 62px;
            background-color: #f8f8fa;
            border-radius: 16px;
            padding-left: 24px;
            font-size: 18px;
            outline: none;
            border: none;
          }
        }
        .submit {
          position: absolute;
          right: 0;
          top: 0;
          input {
            height: 62px;
            outline: none;
            line-height: 62px;
            color: #204289;
            font-size: 18px;
            padding: 0 40px;
            font-weight: 500;
            text-align: center;
            outline: none;
            background-color: inherit;
            border: none;
            cursor: pointer;
          }
        }
        .alerts {
          span {
            color: #1d3b69d9;
            font-size: 14px;
            letter-spacing: -0.3px;
            margin: 15px 0 0;
            text-align: center;
            display: none;
            visibility: hidden;
            &.ok {
              &.visible {
                display: inline-block;
                visibility: visible;
              }
            }
            &.error {
              &.visible {
                display: inline-block;
                visibility: visible;
              }
            }
          }
        }
      }
      .trusted-and-responsible {
        position: relative;
        width: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        margin-top: 30px;
        padding-top: 10px;
        .row {
          width: 100%;
          flex-direction: row;
          flex-wrap: wrap;
        }
        .icon-password::before {
          color: #c7ba89;
          font-size: 22px;
        }
        span {
          &.head {
            flex: 1;
            font-size: 16px;
            font-weight: 600;
            color: #1d3b69;
            margin-bottom: 8px;
            margin-left: 13px;
          }
          &.title {
            font-size: 15px;
            color: #8e8f9d;
            line-height: 19px;
            padding-left: 35px;
          }
        }
        ul {
          &.logos {
            list-style: none;
            padding: 0;
            margin: 0;
            display: inline-block;
            justify-self: flex-start;
            padding-left: 35px;
            li {
              display: inline-block;
              margin: 0 1px;
              i {
                &.logo {
                  display: inline-block;
                  background-size: 100%;
                  background-repeat: no-repeat;
                  position: relative;
                  &.begambleaware {
                    width: 113px;
                    height: 14px;
                    background-image: url(../assets/begambleaware.svg);
                    top: -3px;
                  }
                  &.gamcare {
                    width: 73px;
                    height: 21px;
                    background-image: url(../assets/gamcare.svg);
                  }
                  &.age {
                    width: 23px;
                    height: 23px;
                    top: 1px;
                    background-image: url(../assets/age.svg);
                  }
                  &.dmca {
                    width: 58px;
                    height: 21px;
                    background-image: url(../assets/dmca.png);
                  }
                }
              }
            }
          }
        }
      }
    }
  }
  .stay-in-touch {
    div {
      &.wpforms-container-full {
        display: flex;
        width: 100%;
      }
    }
  }

  .swiper-pagination {
    // display: none;
    // visibility: hidden;
  }
  .swiper-button-next {
    right: 0;
    left: auto;
    color: #204289;
    margin-top: -10px;
  }
  .swiper-button-prev {
    left: 0;
    left: auto;
    color: #204289;
    margin-top: -10px;
  }
  .swiper-button-next::after,
  .swiper-button-prev::after {
    font-size: 22px;
  }
  .swiper-button-disabled {
    cursor: not-allowed !important;
    opacity: 0.5 !important;
  }
  .swiper-pagination {
    bottom: 0px !important;
  }
  .swiper-pagination-bullet {
    width: 16px;
    height: 16px;
    display: inline-block;
    border-radius: 100px;
    border: 2px solid #c7ba89;
    background-color: inherit !important;
    opacity: 1 !important;
  }
  .swiper-pagination-bullet {
    border-color: #1d3b69;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #c7ba89 !important;
  }
  .swiper-pagination-bullet.swiper-pagination-bullet-active {
    background: #1d3b69 !important;
  }
}
@media only screen and (max-width: 1200px) {
  footer {
    .container {
      padding: 0 32px;
    }
  }
}
@media only screen and (max-width: 992px) {
  footer {
    .container {
      padding: 0 60px;
    }
    .stay-in-touch {
      .row {
        flex-direction: column;

        .item-column {
          flex: 1;
          width: 100% !important;
        }
      }
    }
    .follow-us .head {
      display: none;
    }
  }
}
@media only screen and (max-width: 805px) {
  footer {
    .container {
      padding: 0 15px;
    }
    .copyright {
      .row {
        flex-direction: column;

        .item-column {
          flex: 1;
          text-align: center;
          width: 100% !important;
        }
        .follow-us {
          justify-content: center;
        }
      }
    }
  }
}
</style>

import Vue from "vue";
import ElementUI from "element-ui";
import App from "./App.vue";
import "./registerServiceWorker";
import router from "./router";
import store from "./store";
import "element-ui/lib/theme-chalk/index.css";
import "element-ui/lib/theme-chalk/display.css";
import "@/scss/index.scss";
import moment from "moment";
import i18n from "./locales/index";
Vue.config.productionTip = false;
Vue.use(ElementUI);
Vue.filter("formatDate", function (value, format = "YYYY-MM-DD") {
  return moment(value).format(format);
});

Vue.filter("detailsFormatDate", function (value, format = "MMMM D,YYYY") {
  return moment(value).format(format);
});

window.app = new Vue({
  router,
  store,
  i18n,
  render: (h) => h(App),
}).$mount("#app");

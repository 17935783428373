<template>
  <div class="slide-container">
    <div :class="['slide-inner', { show: show }]">
      <div class="row-center" style="margin: 16px">
        <img src="../assets/logo2.png" alt="logo" style="width: 180px" />
        <i class="el-icon-close" @click="$emit('close')"></i>
      </div>

      <el-menu
        :default-active="defaultActive"
        class="el-menu-slide"
        mode="vertical"
        background-color="#4a80ea"
        text-color="#fff"
        active-text-color="#ddd"
        router
        @select="onSelect"
      >
        <el-menu-item
          class="el-menu-list el-item-border bettingSites"
          :index="`BettingSites`"
          :route="`/brazil-gambling-sites`"
          ><span class="icon-ptPT"></span>{{ $t("app_0064") }}</el-menu-item
        >
        <el-menu-item
          class="el-menu-list el-item-border"
          :index="`home`"
          :route="`/home`"
          >{{ $t("app_0224") }}</el-menu-item
        >
        <template v-for="item of navbar">
          <el-submenu
            v-if="item.tabs && item.tabs.length"
            :index="item.Id.toString()"
            :key="item.Id"
            class="el-item-border"
          >
            <template slot="title">{{ item.Title }}</template>
            <el-menu-item
              v-for="sub of item.tabs"
              :key="sub.Id"
              :index="`${item.Id}-${sub.Id}`"
              :route="`/${sub.Id}`"
              >{{ sub.Title }}</el-menu-item
            >
          </el-submenu>
          <el-menu-item
            v-else
            :index="item.Id.toString()"
            :key="item.Id"
            :route="`/${item.Id}`"
            class="el-item-border"
            >{{ item.Title }}</el-menu-item
          >
        </template>
      </el-menu>
      <!-- <el-input
        placeholder="请输入内容"
        prefix-icon="el-icon-search"
        v-model="input2"
        class="slide-Search"
      >
      </el-input> -->
    </div>
    <div class="slide-mask" v-show="show" @click="$emit('close')"></div>
  </div>
</template>

<script>
import { mapState } from "vuex";
const _defActive = "home";
export default {
  name: "SlideMenu",
  props: {
    show: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      isCollapse: true,
      activeNames: [],
      input2: "",
      defaultActive: _defActive,
    };
  },
  computed: {
    ...mapState(["navbar"]),
  },
  created() {
    this.defaultActive = this.$route.params.type;
    this.$root.$on("changeNav", (CategoryId) => {
      this.defaultActive = CategoryId;
    });
  },
  methods: {
    handleChange(val) {
      console.log(val);
    },
    onSelect(e) {
      this.defaultActive = e;
      this.$emit("close");
    },
  },
};
</script>
<style lang="scss">
.slide-container {
  .slide-mask {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(#000, 0.18);
    z-index: 1;
  }
  .slide-inner {
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    transform: translateX(-100%);
    z-index: 999;
    background: #4a80ea;
    transition: 0.5s all;
    &.show {
      transform: translateX(0);
    }
    .el-icon-close {
      position: absolute;
      right: 14px;
      font-size: 26px;
      color: rgb(255, 255, 255);
    }
  }
  .el-menu {
    border: none;
    .el-item-border {
      border-bottom: 1px solid #60aeff;
    }
    .el-submenu__title {
      background-color: #4a80ea !important;
    }
    .el-submenu__title i {
      color: #fff;
    }
    .bettingSites {
      .icon-ptPT {
        background-image: url("/src/assets/lang-map.png");
        background-repeat: no-repeat;
        width: 20px;
        height: 14px;
        display: inline-block;
        background-size: 20px auto;
        background-position-y: -224px;
        margin: 0px 4px 2px 0px;
        padding: 0;
      }
    }
  }
  .el-menu--inline,
  .el-menu--inline .el-menu-item {
    background-color: #3b66bb !important;
  }
  .el-menu--inline .el-menu-item {
    padding: 0 20px !important;
  }

  .el-menu-item.is-active,
  .el-menu-item:hover {
    //background-color: #3b66bb !important;
  }
  .el-menu-item.is-active {
    &::before {
      content: "\e6dc";
      font-family: element-icons !important;
      speak: none;
      font-style: normal;
      font-weight: 400;
      font-variant: normal;
      text-transform: none;
      vertical-align: baseline;
      float: right;
      -webkit-font-smooth: grayscale;
    }
  }

  .slide-collapse {
    border: none;
    margin-left: auto;
    margin-right: auto;
    width: 220px;
    height: 90vh;
    overflow-y: auto;
    .el-collapse-item__header {
      color: #fff;
      border-bottom: 1px solid #60aeff;
      background-color: transparent;
    }
    .el-collapse-item__wrap {
      border-bottom: 1px solid #60aeff;
      background-color: transparent;
      .el-collapse-item__content {
        color: #fff;
      }
    }
  }
  .slide-Search {
    margin: 20px 30px 0px 30px;
    width: 180px;
    input {
      border-radius: 18px;
    }
  }
}
</style>

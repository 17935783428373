import Vue from "vue";
import VueRouter from "vue-router";
import HomeView from "../views/Home.vue";
import NewsMain from "../views/NewsMain";
import NewsDetails from "../views/NewsDetails";
import BettingSites from "../views/BettingSites";
Vue.use(VueRouter);

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    redirect: "/1",
    children: [
      {
        path: "/brazil-gambling-sites",
        name: "BettingSites",
        meta: {
          hideSilderBar: true,
        },
        component: BettingSites,
      },
      {
        path: "/:type",
        name: "main",
        component: NewsMain,
      },
      {
        path: "/news/:id",
        name: "newsdetails",
        meta: {
          hideBanner: true,
        },
        props: true,
        component: NewsDetails,
      },
    ],
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes,
});

export default router;

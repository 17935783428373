<template>
  <div class="main-sidebar">
    <div class="sidebar-ad-banner" v-if="advertiesSort[5]">
      <a :href="advertiesSort[5][0].LinkUrl" target="_blank">
        <img
          :src="advertiesSort[5][0].ImageUrl"
          alt=""
          style="object-fit: cover; width: 100%; height: 100%"
        />
      </a>
    </div>
    <div class="panel">
      <div class="panel-header row-between">
        <div class="title">{{ $t("app_0026") }}</div>
        <el-link icon="el-icon-plus">{{ $t("app_0027") }}</el-link>
      </div>

      <div class="panel-body">
        <div
          class="brand-item"
          v-for="(item, index) of brandSiteList"
          :key="index"
        >
          <div class="brand-logo">
            <a :href="item.LinkUrl" target="_blank">
              <img
                :src="item.ImageUrl"
                alt=""
                style="object-fit: cover; width: 100%; height: 100%"
              />
            </a>
          </div>
          <div class="brand-info">
            <a class="name" :href="item.LinkUrl" target="_blank">{{
              item.Title
            }}</a>
            <div class="keyword">
              <el-rate :value="item.Star" disabled text-color="#ff9900">
              </el-rate>
            </div>
            <div class="row-start desc">
              <!-- <div class="label">{{ $t("app_0030") }}:</div> -->
              <div class="value">{{ item.SubTitle }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="sidebar-ad-banner" v-if="advertiesSort[5]">
      <a :href="advertiesSort[5][1].LinkUrl" target="_blank">
        <img
          :src="advertiesSort[5][1].ImageUrl"
          alt=""
          style="object-fit: cover; width: 100%; height: 100%"
        />
      </a>
    </div>

    <!-- <div class="panel">
      <div class="panel-header row-between">
        <div class="title">{{ $t("app_0031") }}</div>
        <el-link icon="el-icon-plus">{{ $t("app_0027") }}</el-link>
      </div>

      <div class="panel-body">
        <div class="news-item" v-for="item of 5" :key="item">
          <div class="news-img"></div>
          <div class="news-info">
            <div class="title">
              小口利成为首行谐足美国印太司令部的非佯兵瓜犹
            </div>
            <div class="row-start more-info">
              <div class="date">12-01 06:09:25</div>
            </div>
          </div>
        </div>
      </div>
    </div> -->
  </div>
</template>

<script>
import { getAdvBrandSiteList } from "@/axios/api";
import { mapGetters } from "vuex";

export default {
  name: "MainSidebar",
  data() {
    return {
      rightBanner: {},
      brandSiteList: {},
    };
  },
  computed: {
    ...mapGetters(["advertiesSort"]),
  },
  mounted() {
    this.getAdvBrandSiteList();
  },
  methods: {
    async getAdvBrandSiteList() {
      const res = await getAdvBrandSiteList();
      this.brandSiteList = res.data;
    },
  },
};
</script>
<style lang="scss">
.main-sidebar {
  width: 330px;
  margin-left: 10px;
  .sidebar-ad-banner {
    width: 100%;
    height: 400px;
    background: #ddd;
    margin-bottom: 10px;
  }

  .panel {
    background: #fff;
    &-header {
      margin: 0 16px;
      padding: 16px 0;
      border-bottom: 1px solid #ddd;
      .title {
        font-size: 16px;
        font-weight: bold;
      }
      .el-link {
        font-size: 12px;
      }
    }

    &-body {
      padding-bottom: 16px;
      margin-bottom: 10px;
      .news-item {
        display: flex;
        margin: 16px;

        .news-img {
          width: 100px;
          height: 60px;
          background: #ddd;
        }

        .news-info {
          flex: 1;
          margin: 0 16px;
          .title {
            font-size: 14px;
            text-overflow: ellipsis;
            font-weight: bold;
          }

          .content {
            font-size: 14px;
            color: #666;
          }

          .more-info {
            font-size: 12px;
            color: #999;

            .times {
              margin-left: 10px;
            }
          }
        }
      }
    }

    .brand-item {
      display: flex;
      margin: 16px;
      .brand-logo {
        width: 100px;
        height: 60px;
        background: #ddd;
      }
      .brand-info {
        line-height: 20px;
        margin-left: 16px;
        .title {
          font-size: 16px;
          font-weight: bold;
        }
        .tag {
          background: red;
          color: #fff;
          font-size: 12px;
          padding: 2px 4px;
          border-radius: 2px;
        }

        .desc {
          font-size: 12px;
          color: #999;
        }
      }
    }
  }
}
</style>
